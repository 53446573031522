import React, { useState } from "react";
import Layout from "../../components/layout";
import Hero from "../../components/FindTransaction/Hero";
import Select from "../../components/utils/Select";
import OtpBox from "../../components/utils/OtpBox";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  otpSend,
  otpVerify,
  trackApplication,
} from "../../redux/actions/serviceAction";
import CashLoanTrack from "../../components/Track/CashLoanTrack";
import CryptoLoanTrack from "../../components/Track/CryptoLoanTrack";
import SellTrack from "../../components/Track/SellTrack";
import EscrowTrack from "../../components/Track/EscrowTrack";
import DepositTrack from "../../components/Track/DepositTrack";
import PaymentTrack from "../../components/Track/PaymentTrack";
import BuyTrack from "../../components/Track/BuyTrack";
import SwapTrack from "../../components/Track/SwapTrack";

const FindTransaction = () => {
  const [queryData, setqueryData] = useState({
    email: "",
    applicationId: "",
    type: "",
  });

  const [otp, setotp] = useState("");

  const { track, otpSent, otpVerified } = useSelector((state) => state.service);

  const dispatch = useDispatch();

  const applyHandler = () => {
    if (!queryData.email) {
      return toast.warn("Email is required");
    }
    if (!queryData.applicationId) {
      return toast.warn("Application ID is required");
    }

    if (!queryData.type) {
      return toast.warn("Please select Application Type");
    }

    dispatch(
      trackApplication({
        email: queryData.email,
        id: queryData.applicationId,
        type: queryData.type,
      })
    );
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!queryData.email) {
      return toast.error(" Email is required");
    }
    if (!queryData.applicationId) {
      return toast.error("Transaction ID is required");
    }

    dispatch(otpSend({ email: queryData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: queryData.email, otp }));
  };

  const getFields = () => {
    switch (queryData.type) {
      case "cash-loan":
        return <CashLoanTrack />;
      case "crypto-loan":
        return <CryptoLoanTrack />;
      case "deposit":
        return <DepositTrack />;
      case "crypto-sell":
        return <SellTrack />;
      case "crypto-buy":
        return <BuyTrack />;
      case "payment":
        return <PaymentTrack />;
      case "escrow":
        return <EscrowTrack />;

      case "swap":
        return <SwapTrack />;
      default:
        return <div></div>;
    }
  };

  return (
    <Layout>
      <Hero />

      <div className="formContainer3">
        <h2>Track Application</h2>

        <div className="form3">
          <div className="input">
            <label htmlFor="">Email Address</label>
            <input
              type="text"
              placeholder="Email(That was used to create transaction) "
              value={queryData.email}
              onChange={(e) =>
                setqueryData({ ...queryData, email: e.target.value })
              }
            />
          </div>
          <div className="input">
            <label htmlFor=""> Transaction ID</label>
            <input
              type="text"
              value={queryData.id}
              placeholder="Transaction ID "
              onChange={(e) =>
                setqueryData({
                  ...queryData,
                  applicationId: e.target.value,
                })
              }
            />
          </div>

          <Select
          backgroundColor={"#8f4a1a"}
            label=" Application Type"
            value={queryData.type}
            options={applications}
            property="type"
            className="full_width"
            onChange={(item) =>
              setqueryData({
                ...queryData,
                type: item.type,
              })
            }
          />

          {otpSent && !otpVerified && (
            <OtpBox
              value={otp}
              onChange={(value) => setotp(value)}
              onClick={verifyOtp}
            />
          )}

          <div className="btn_container ">
            {otpVerified && otpSent && (
              <button
                onClick={applyHandler}
                className="btn btn_secondary btn_large"
              >
                Track
              </button>
            )}
            {!otpSent && !otpVerified && (
              <button
                onClick={sendOtp}
                className="btn btn_secondary btn_large"
                disabled={otpSent}
              >
                Send Otp
              </button>
            )}
          </div>

          {track && (
            <div className="mt-5">
              <div className="row gy-4">{getFields()}</div>
            </div>
          )}

          <p>
            *For assistance or enquiries contact Support@99krypto.com with your
            transaction ID and email address
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default FindTransaction;

const applications = [
  {
    type: "cash-loan",
  },
  {
    type: "crypto-loan",
  },
  {
    type: "deposit",
  },

  {
    type: "crypto-sell",
  },
  {
    type: "crypto-buy",
  },
  {
    type: "swap",
  },
  {
    type: "payment",
  },
  {
    type: "escrow",
  },
];
