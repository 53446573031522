import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AnnounceModal from "../utils/AnnounceModal";
import Spinner from "../utils/Spinner";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";

const Layout = (props) => {
  const [active, setactive] = useState(false);
  const { loading } = useSelector((state) => state.service);
  const { user, isAuthenticated } = useSelector((state) => state.user);

  const { title, children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const OnClick = () => {
    setIsOpen(!isOpen);
  };

  const closeModal = () => {
    setmodalOpen(false);
    localStorage.setItem("modalOpen", "false");
  };

  useEffect(() => {
    const _d = localStorage.getItem("modalOpen");
    if (_d) {
      setmodalOpen(false);
    } else {
      setmodalOpen(true);
    }
    if (pathname == "/") {
      setmodalOpen(false);
    } else if (pathname == "/about-us") {
      setmodalOpen(false);
    } else if (pathname == "/white-paper") {
      setmodalOpen(false);
    }

    window.addEventListener("load", () => {
      localStorage.removeItem("modalOpen");
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated && !user.kyc_submitted) {
      return navigate("/kyc");
    }
  }, [isAuthenticated]);

  return (
    <div>
      <AnnounceModal modalOpen={modalOpen} closeModal={closeModal} />
      <Header active={active} setactive={setactive} />
      <Sidebar active={active} setactive={setactive} />
      {loading ? <Spinner /> : <> {children}</>}

      <Footer />
    </div>
  );
};

export default Layout;
