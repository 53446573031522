import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import { forgotPassword } from "../../redux/actions/userAction";
import { Slide } from "react-reveal";
import { Link } from "react-router-dom";
const ForgetPassword = () => {
  const [email, setemail] = useState("");
  const [clicked, setclicked] = useState(false);

  const dispatch = useDispatch();

  const applyHandler = () => {
    if (!email) {
      return toast.warn("Email is required");
    }

    const form = new FormData();

    form.set("email", email);

    dispatch(forgotPassword(form));

    setclicked(true);
  };

  return (
    <Layout>
      <div>
        <div className="formContainer3">
          <h2>Reset Password</h2>
          <Slide bottom>
            {clicked ? (
              <h2 className="gradiant_text">
                A reset password link has been sent to your email address.
                Please Check your Inbox{" "}
              </h2>
            ) : (
              <div className="form3" style={{ width: "600px" }}>
                <div className="input full_width">
                  <label htmlFor=""> Enter Your Email</label>
                  <input
                    type="email"
                    placeholder="Email... "
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>

                <div className="btn_container full_width">
                  <button
                    disabled={clicked}
                    className="btn btn_secondary"
                    onClick={applyHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
            <button className="btn btn_gradiant">
              <Link to="/">Go home</Link>{" "}
            </button>
          </Slide>
        </div>
      </div>
    </Layout>
  );
};

export default ForgetPassword;
