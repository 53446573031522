import React from "react";
import { useSelector } from "react-redux";

const EscrowTrack = () => {
  const { track: escrowData, setting } = useSelector((state) => state.service);

  return (
    <div className="track">
      <div className="info">
        <div className="key">
          <label> Status</label>
        </div>
        <div className="value">{escrowData.status}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Escrow Coin</label>
        </div>
        <div className="value">{escrowData.coin.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Escrow Quantity</label>
        </div>
        <div className="value">{escrowData.quantity}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Party 1 Email</label>
        </div>
        <div className="value">{escrowData.email1}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Party 2 Email</label>
        </div>
        <div className="value">{escrowData.email2}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Notes</label>
        </div>
        <div className="value">{escrowData.notes}</div>
      </div>
    </div>
  );
};

export default EscrowTrack;
