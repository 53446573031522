import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import styles from "./swap.module.scss";
import Select from "../../components/utils/Select";
import OtpBox from "../../components/utils/OtpBox";
import Upload from "../../components/utils/Upload";
import { Slide } from "react-reveal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  applyCryptoSwap,
  otpSend,
  otpVerify,
} from "../../redux/actions/serviceAction";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsCheck2All } from "react-icons/bs";

const Swap = () => {
  const [tabContent, settabContent] = useState("stableswap");

  const { coins, wallets, setting, success, otpSent, otpVerified } =
    useSelector((state) => state.service);
  const [wallet, setwallet] = useState("");
  const [screenShot, setscreenShot] = useState(null);
  const [addContent, setaddContent] = useState(false);
  const [isCopied, setisCopied] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [otp, setotp] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [cryptoSwapData, setcryptoSwapData] = useState({
    quantity: null,
    crypto: null,
    receivingCrypto: null,
    receivingQuantity: "",
    receivingAddress: "",
    email: "",
  });

  const getPrice = async (coin, receivingCoin) => {
    if (!cryptoSwapData.crypto) return;
    if (!cryptoSwapData.quantity) return;
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin},${receivingCoin}&vs_currencies=usd`
    );
    const _coin = data[coin];
    const _receivingCoin = data[receivingCoin];
    const _receivingCoinPrice = parseInt(_receivingCoin["usd"]);
    const _price = parseInt(_coin["usd"]);
    const qtyprice = _price * cryptoSwapData.quantity;
    const price = (qtyprice / _receivingCoinPrice) * setting.swapCharge * 0.01;

    setcryptoSwapData({
      ...cryptoSwapData,
      receivingQuantity: price,
    });
  };

  useEffect(() => {
    setwallet(
      wallets.find((wal) => wal.coin?._id == cryptoSwapData?.crypto?._id)
    );
    getPrice(cryptoSwapData.crypto?.id, cryptoSwapData.receivingCrypto?.id);
  }, [
    cryptoSwapData.crypto,
    cryptoSwapData.quantity,
    cryptoSwapData.receivingCrypto,
  ]);

  const submitCryptoSwap = (e) => {
    e.preventDefault();
    if (!cryptoSwapData.email) {
      return toast.warn("Email Is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot is required");
    }

    if (!otpVerified) {
      return toast.warn("Please Verify Your Email");
    }

    const form = new FormData();
    form.set("email", cryptoSwapData.email);
    form.set("quantity", cryptoSwapData.quantity);
    form.set("crypto", cryptoSwapData.crypto?._id);
    form.set("receivingCrypto", cryptoSwapData.receivingCrypto?._id);
    form.set("receivingQuantity", cryptoSwapData.receivingQuantity);
    form.set("receivingAddress", cryptoSwapData.receivingAddress);
    form.set("screenShot", screenShot);

    dispatch(applyCryptoSwap(form));

    setcryptoSwapData({
      quantity: null,
      crypto: null,
      receivingCrypto: null,
      receivingQuantity: "",
      receivingAddress: "",
      email: "",
    });
  };

  const clickHandler = () => {
    if (!cryptoSwapData.crypto) {
      return toast.warn("Plese select  Crypto");
    }
    if (!cryptoSwapData.receivingCrypto) {
      return toast.warn("Plese select receiving Crypto");
    }
    if (!cryptoSwapData.quantity) {
      return toast.warn("Quantity is required");
    }
    setaddContent(true);
  };

  const sendOtp = (e) => {
    e.preventDefault();

    if (!cryptoSwapData.email) {
      return toast.warn("Enter your email");
    }
    dispatch(otpSend({ email: cryptoSwapData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    if (!cryptoSwapData.email) {
      return toast.error("Email is Required");
    }
    dispatch(otpVerify({ email: cryptoSwapData.email, otp }));
  };

  const fileChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (success) {
      return navigate("/success");
    }
  }, [success]);

  return (
    <Layout>
      <div className={styles.swap}>
        <div className={styles.top}>
          <div className={styles.left}>
            <div className={styles.tabs}>
              <div
                className={`${styles.tab} ${
                  tabContent == "stableswap" && styles.active
                } `}
                onClick={() => settabContent("stableswap")}
              >
                Stable Swap
              </div>
              <div
                className={`${styles.tab} ${
                  tabContent == "swap" && styles.active
                } `}
                onClick={() => settabContent("swap")}
              >
                Swap
              </div>
            </div>
            {tabContent == "stableswap" ? (
              <Slide bottom>
                <div className={styles.tabContent}>
                  <div className={styles.icons}>
                    <img src={cryptoSwapData.crypto?.logo?.url} alt="" />
                    <img src="./images/arrow.png" alt="" />
                    <img
                      src={cryptoSwapData?.receivingCrypto?.logo?.url}
                      alt=""
                    />
                  </div>
                  <Select
                    label={""}
                    value={cryptoSwapData.crypto?.name}
                    options={coins}
                    property="code"
                    img="253"
                    onChange={(item) => {
                      setcryptoSwapData({
                        ...cryptoSwapData,
                        crypto: item,
                      });
                    }}
                  />
                  <div className={styles.input}>
                    <label htmlFor="">Quantity</label>
                    <input
                      type="text
              "
                      placeholder="Quantity"
                      value={cryptoSwapData.quantity}
                      onChange={(e) =>
                        setcryptoSwapData({
                          ...cryptoSwapData,
                          quantity: e.target.value,
                        })
                      }
                    />
                  </div>
                  <Select
                    value={cryptoSwapData?.receivingCrypto?.name}
                    options={coins}
                    property="code"
                    img={"13"}
                    onChange={(item) =>
                      setcryptoSwapData({
                        ...cryptoSwapData,
                        receivingCrypto: item,
                      })
                    }
                  />

                  <div className={styles.input}>
                    <label htmlFor=""> Receiving Quantity</label>
                    <input
                      type="text
              "
                      placeholder="Receiving Quantity.."
                      value={cryptoSwapData.receivingQuantity}
                      disabled={true}
                    />
                  </div>

                  <div className="btn_container">
                    <a href="#form3">
                      <button
                        className="btn btn_secondary btn_large"
                        onClick={clickHandler}
                      >
                        Apply
                      </button>
                    </a>
                  </div>
                </div>
              </Slide>
            ) : (
              <Slide bottom>
                <div className={styles.tabContent}>
                  <h3>Coming Soon</h3>
                </div>
              </Slide>
            )}
          </div>
          <div className={styles.right}>
            <Slide right>
              <img src="/images/swap.png" alt="" />
            </Slide>
            <Slide bottom>
              <div>
                <div>
                  <h3 className="gradiant_text">
                    <div className="tick">
                      <BsCheck2All />
                    </div>
                    No Slippage
                  </h3>
                </div>
                <div>
                  <h3 className="gradiant_text">
                    {" "}
                    <div className="tick">
                      <BsCheck2All />
                    </div>
                    Fast and easy swapping
                  </h3>
                </div>

                <div>
                  <h3 className="gradiant_text">
                    <div className="tick">
                      <BsCheck2All />
                    </div>
                    Immediate Transfer to Your wallet*
                  </h3>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
      {addContent && (
        <div className="formContainer3">
          <div className="form3" id="form3">
            <div className="input full_width">
              <label htmlFor="">Your Email</label>
              <input
                type="text"
                value={cryptoSwapData.email}
                placeholder="Enter Your Email.."
                disabled={otpSent}
                onChange={(e) =>
                  setcryptoSwapData({
                    ...cryptoSwapData,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="input full_width">
              <label htmlFor="">Your wallet Address to receive Crypto</label>
              <input
                type="text"
                value={cryptoSwapData?.receivingAddress}
                onChange={(e) =>
                  setcryptoSwapData({
                    ...cryptoSwapData,
                    receivingAddress: e.target.value,
                  })
                }
              />
            </div>
            <div className="input full_width">
              <label htmlFor=""> Send Crypto to</label>
              <input type="text" value={wallet?.address} disabled />
              <CopyToClipboard
                text={wallet?.address}
                onCopy={() => setisCopied(true)}
              >
                <button className="copyButton">
                  {isCopied ? "Copied" : "Copy"}{" "}
                </button>
              </CopyToClipboard>
            </div>

            <Upload onChange={fileChange} src={screenShot} />
            {otpSent && !otpVerified && (
              <OtpBox
                value={otp}
                onChange={(value) => setotp(value)}
                onClick={verifyOtp}
              />
            )}
            <div className="check ">
              <input
                type="checkbox"
                name=""
                id="check"
                onChange={() => setisChecked(!isChecked)}
              />
              <label htmlFor="check">terms & Cnditions</label>
            </div>

            <div className="btn_container">
              {!otpSent ? (
                <button
                  className="btn btn_secondary btn_large"
                  onClick={sendOtp}
                >
                  Send OTP
                </button>
              ) : (
                <button
                  className="btn btn_secondary btn_large"
                  onClick={submitCryptoSwap}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Swap;
