import moment from "moment";
import { useSelector } from "react-redux";

const CryptoLoanTrack = () => {
  const { track: lendData } = useSelector((state) => state.service);

  return (
    <div className="track">
      <div className="info">
        <div className="key">
          <label> Status</label>
        </div>
        <div className="value">{lendData?.status}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Loan Coin</label>
        </div>
        <div className="value">{lendData?.lendCoin?.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Loan Quantity</label>
        </div>
        <div className="value">{lendData?.quantity}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Collateral Coin</label>
        </div>
        <div className="value">{lendData?.collateralCoin?.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Collateral Quantity</label>
        </div>
        <div className="value">{lendData?.collateralRequired}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Total to be paid back</label>
        </div>
        <div className="value">{lendData?.totalTobePaid}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Outstanding loan</label>
        </div>
        <div className="value">
          {lendData?.totalTobePaid - lendData?.partialRepayment}
        </div>
      </div>
      <div className="info">
        <div className="key">
          <label> Loan Start Date</label>
        </div>
        <div className="value">
          {moment(lendData.loanStartDate).format("MMMM Do YYYY, h:mm:ss a")}
        </div>
      </div>
      <div className="info">
        <div className="key">
          <label> Loan End Date</label>
        </div>
        <div className="value">
          {moment(lendData.loanEndDate).format("MMMM Do YYYY, h:mm:ss a")}
        </div>
      </div>
    </div>
  );
};

export default CryptoLoanTrack;
