import React from "react";
import { useEffect } from "react";
import DashboardLayout from "../../components/Vendor/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Vendor = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated && !user.kyc_submitted) {
      return navigate("/kyc");
    }
  }, [isAuthenticated]);

  return (
    <div>
      <DashboardLayout vendor={true}>
        <h1>Dashboard</h1>
      </DashboardLayout>
    </div>
  );
};

export default Vendor;
