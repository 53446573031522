import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { kycOtpVerify } from "../../redux/actions/userAction";

const OtpVerify = ({ step, setstep, userData }) => {
  const [otp, setotp] = useState("");
  const { otpVerified } = useSelector((state) => state.service);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const verify = () => {
    dispatch(kycOtpVerify({ email: user.email, otp }));
  };

  useEffect(() => {
    if (otpVerified) {
      setstep(step + 1);
    }
  }, [otpVerified]);
  return (
    <div
      style={{
        width: "max-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px auto",
      }}
    >
      <div
        className="formContainer2"
        style={{ width: "80vw", maxWidth: "600px" }}
      >
        <div className="form2">
          <div className="input full_width ">
            <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
              Verification Code
            </label>
            <br />
            <input
              type="text"
              placeholder="6 digit verification Code"
              value={otp}
              onChange={(e) => setotp(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="btnContainer" style={{ margin: "10px" }}>
        <button className="btn btn_primary" onClick={verify} disabled={!otp}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default OtpVerify;
