import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import { updatePassword } from "../../redux/actions/userAction";
import {Slide } from 'react-reveal'

const ChangePassword = () => {
  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const applyHandler = () => {
    if (!oldPassword) {
      return toast.warn("Old Password is required");
    }
    if (!newPassword) {
      return toast.warn("New Password ID is required");
    }

    dispatch(
      updatePassword({
        oldPassword,
        newPassword,
        confirmPassword: newPassword,
      })
    );
  };

  const { user, isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {}, [isAuthenticated]);

  return (
    <Layout>
      <div>
        <div className="formContainer3">
            <h2>Change Password</h2>
        <Slide bottom >
        <div className="form3" style={{ width: "600px" }}>
            <div className="input full_width">
              <label htmlFor="">Old Password</label>
              <input
                type="text"
                placeholder="Old password.. "
                value={oldPassword}
                onChange={(e) => setoldPassword(e.target.value)}
              />
            </div>
            <div className="input full_width">
              <label htmlFor="">New Password</label>
              <input
                type="text"
                placeholder="New Password "
                value={newPassword}
                onChange={(e) => setnewPassword(e.target.value)}
              />
            </div>
            <div className="btn_container full_width">
              <button className="btn btn_secondary" onClick={applyHandler}>
                Change Password{" "}
              </button>
            </div>
          </div>
        </Slide>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
