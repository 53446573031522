import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Layout from "../../components/layout";
import { runFireworks } from "../../components/utils/others";
import { setSuccessFalse } from "../../redux/actions/serviceAction";

const Success = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSuccessFalse());

    runFireworks();
  }, []);
  return (
    <Layout title="Thanks || 99Krypto">
      <div className="thankyou_container">
        <h1 className="">
          Thank you for Transacting with us.You will receive an Email shortly,
          With Transaction Details and other Necessary Information
        </h1>

        <h2 className="">
          Please Note: In some cases, Email may be delayed for 6 to 12 hours
          depending on the type of transaction and other Confirmations.
        </h2>
      </div>
    </Layout>
  );
};

export default Success;
