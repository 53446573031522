import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./header.scss";
import { useSelector } from "react-redux";
import { BsSun, BsMoonStars } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";

//Web 3 imports
import { networkParams } from "../../../web3/networks";
import { toHex, truncateAddress } from "../../../web3/utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../../../web3/providerOptions";

const web3Modal = new Web3Modal({
  network: "rinkeby", // optional
  cacheProvider: true, // optional
  isableInjectedProvider: false,
  providerOptions, // required,
  theme: "dark",
});

const Header = ({ active, setactive }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [scroll, setscroll] = useState("");
  const [theme, settheme] = useState("");
  // connect wallet states
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();

  const changeTheme = () => {
    if (theme == "light") {
      window.document.body.classList.add("dark");
      localStorage.setItem("theme", "dark");
      settheme("dark");
    } else {
      window.document.body.classList.remove("dark");
      localStorage.setItem("theme", "light");
      settheme("light");
    }
  };

  useEffect(() => {
    settheme(window.localStorage.getItem("theme"));
  }, []);

  useEffect(() => {
    window.document.body.className = theme == "light" ? "light" : "dark";
  }, [theme]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 100) {
        setscroll(true);
      } else {
        setscroll(false);
      }
    });
  }, []);

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  const handleInput = (e) => {
    const msg = e.target.value;
    setMessage(msg);
  };

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]],
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account],
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature],
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  return (
    <div
      className="header"
      style={{
        position: scroll ? "fixed" : "sticky",
        boxShadow: scroll && "0px -3px 15px 4px rgba(0,0,0,0.75)",
      }}
    >
      <div className="container">
        <div className="logo">
          <a href="/">
            <img src="images/logo.png" alt="" />
          </a>
        </div>
        <div className="logo2">
          <a href="/">
            <img src="symbol.png" alt="" />
          </a>
        </div>
        <div className="menu">
          <ul>
            <li>
              <Link to={"/cash-loans"}>
                <a>Cash Loans </a>{" "}
              </Link>{" "}
            </li>
            <li>
              <Link to={"/crypto-loans"}>
                <a>crypto Loans </a>{" "}
              </Link>{" "}
            </li>
            <li>
              <Link to={"/deposits"}>
                <a>Deposits </a>{" "}
              </Link>{" "}
            </li>
            <li>
              <Link to={"/payment"}>
                <a>Payment </a>{" "}
              </Link>{" "}
            </li>

            <li className="megaMenuLink">
              <a href="" className="link">
                All services{" "}
              </a>
              <div className="megaMenu">
                <div className="wrapper">
                  <ul>
                    <li>
                      <Link to={"/cash-loans"}>
                        <a>Cash Loans </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/crypto-loans"}>
                        <a>crypto Loans </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/deposits"}>
                        <a>Deposits </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/payment"}>
                        <a>Payment </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/buy-sell"}>
                        <a>Buy/Sell </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/swap"}>
                        <a>Swap </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/escrow"}>
                        <a>Escrow </a>{" "}
                      </Link>{" "}
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to={"/stake"}>
                        <a>Stake </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/find-transaction"}>
                        <a>Find Transaction </a>{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/press"}>
                        <a>Press </a>{" "}
                      </Link>{" "}
                    </li>
                    {/* <li>
                      <Link to={"/white-paper"}>
                        <a>White paper </a>{" "}
                      </Link>{" "}
                    </li> */}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="actions">
          {isAuthenticated ? (
            <a href="/account" className="signIn theme_switcher">
              <BiUserCircle />
            </a>
          ) : (
            <a href="/signin" className="signIn">
              Sign In
            </a>
          )}
          <div className="theme_switcher" onClick={() => changeTheme()}>
            {theme == "light" ? <BsMoonStars /> : <BsSun />}
          </div>{" "}
          <button onClick={connectWallet} className="wallet">
            Connect Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
