import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { BiMenu } from "react-icons/bi";

const DashboardSidebar = ({ vendor }) => {
  const [active, setactive] = useState(false);
  return (
    <div className={`${styles.sidebar} ${active && styles.active}`}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.menuIcon} onClick={() => setactive(!active)}>
            <BiMenu size={32} />
          </div>
        </div>

        <div className={styles.links}>
          {!vendor ? (
            <ul>
              <li>
                <Link to={"/"}>
                  <a>Home</a>
                </Link>
              </li>
              <li>
                <Link to={"/account/cash-loans"}>
                  <a>Cash Loans </a>
                </Link>
              </li>
              <li>
                <Link to={"/account/crypto-loans"}>
                  <a>Crypto Loans </a>
                </Link>
              </li>
              <li>
                <Link to={"/account/deposits"}>
                  <a>Deposits </a>
                </Link>
              </li>
              <li>
                <Link to={"/account/payment"}>
                  <a>Payment </a>
                </Link>
              </li>
              <li>
                <Link to={"/account/buy"}>
                  <a>Buy </a>
                </Link>
              </li>
              <li>
                <Link to={"/account/sell"}>
                  <a>Sell </a>
                </Link>
              </li>
              <li>
                <Link to={"/account/swap"}>
                  <a>Swap </a>
                </Link>
              </li>
              <li>
                <Link to={"/account/escrow"}>
                  <a>Escrow </a>
                </Link>
              </li>
              <hr />

              <li>
                <Link to={"/find-transaction"}>
                  <a>Find Transaction </a>
                </Link>
              </li>
              <hr />

              <li>
                <Link to={"/change-password"}>
                  <a>Change Password </a>
                </Link>
              </li>
              <li>
                <Link to={"/reset-password"}>
                  <a>Reset Password </a>
                </Link>
              </li>

         
            </ul>
          ) : (
            <ul>
              <li>
                <Link to={"/"}>
                  <a>Home</a>
                </Link>
              </li>
              <li>
                <Link to={"/vendor/cash-loans"}>
                  <a>Cash Loans </a>
                </Link>
              </li>

              <li>
                <Link to={"/vendor/buy"}>
                  <a>Buy </a>
                </Link>
              </li>
              <li>
                <Link to={"/vendor/sell"}>
                  <a>Sell </a>
                </Link>
              </li>

              <li>
                <Link to={"/vendor/profile"}>
                  <a>Profile </a>
                </Link>
              </li>

              <hr />

              <li>
                <Link to={"/change-password"}>
                  <a>Change Password </a>
                </Link>
              </li>
           
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
