import {
  applyLoanConstants,
  cashToCryptoConstants,
  cryptoCoinConstants,
  cryptoSwapConstants,
  cryptoToCashConstants,
  currencyConstants,
  depositConstants,
  escrowConstants,
  lendConatants,
  otpConstants,
  paymentConstants,
  settingConstants,
  timePeriodConstants,
  trackApplicationConstants,
  walletConstants,
} from "../constants/serviceConstants";

const initState = {
  cryptoLoans: [],
  cryptoLoan: {},
  cryptoDeposits: [],
  cryptoDeposit: {},
  cryptosToCash: [],
  cryptoToCash: [],
  cashToCryptos: [],
  cashToCrypto: [],
  cryptoSwaps:[],
  transactions:{},
  currencies: [],
  coins: [],
  timePeriods: [],
  wallets: [],
  track: null,
  setting: null,
  loading: false,
  success: false,
  otpSent: false,
  otpVerified: false,
};

export const serviceReducer = (state = initState, action) => {
  switch (action.type) {
    case currencyConstants.GET_CURRENCY_SUCCESS:
      return (state = {
        ...state,
        currencies: action.payload,
      });
    case timePeriodConstants.GET_TIME_PERIODS_SUCCESS:
      return (state = {
        ...state,
        timePeriods: action.payload,
      });
    case cryptoCoinConstants.GET_CRYPTO_COINS_SUCCESS:
      return (state = {
        ...state,
        coins: action.payload,
      });
    case walletConstants.GET_WALLETS_SUCCESS:
      return (state = {
        ...state,
        wallets: action.payload,
      });
    case settingConstants.GET_SETTING_SUCCESS:
      return (state = {
        ...state,
        setting: action.payload,
      });
    case applyLoanConstants.APPLY_LOAN_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });

    case applyLoanConstants.APPLY_LOAN_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });

    case applyLoanConstants.APPLY_LOAN_FAILURE:
      return (state = {
        ...state,
        loading: false,
        success: false,

      });

    case lendConatants.APPLY_LEND_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });

    case lendConatants.APPLY_LEND_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });
    case lendConatants.APPLY_LEND_FAILURE:
      return (state = {
        ...state,
        loading: false,
      });
    case depositConstants.APPLY_DEPOSIT_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });
    case depositConstants.APPLY_DEPOSIT_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });
    case depositConstants.APPLY_DEPOSIT_FAILURE:
      return (state = {
        ...state,
        loading: false,
      });
    case cryptoToCashConstants.APPLY_CRYPTO_TO_CASH_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });
    case cryptoToCashConstants.APPLY_CRYPTO_TO_CASH_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });
    case cryptoToCashConstants.APPLY_CRYPTO_TO_CASH_FAILURE:
      return (state = {
        ...state,
        loading: false,
      });
    case cashToCryptoConstants.APPLY_CASH_TO_CRYPTO_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });
    case cashToCryptoConstants.APPLY_CASH_TO_CRYPTO_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });
    case cashToCryptoConstants.APPLY_CASH_TO_CRYPTO_FAILURE:
      return (state = {
        ...state,
        loading: false,
      });
    case cryptoSwapConstants.APPLY_CRYPTO_SWAP_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });
    case cryptoSwapConstants.APPLY_CRYPTO_SWAP_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });
    case cryptoSwapConstants.APPLY_CRYPTO_SWAP_FAILURE:
      return (state = {
        ...state,
        loading: false,
      });
    case paymentConstants.APPLY_PAYMENT_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });
    case paymentConstants.APPLY_PAYMENT_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });
    case paymentConstants.APPLY_PAYMENT_FAILURE:
      return (state = {
        ...state,
        loading: false,
      });
    case escrowConstants.APPLY_ESCROW_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });
    case escrowConstants.APPLY_ESCROW_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        success: true,
        otpSent: false,
        otpVerified: false,
      });
    case escrowConstants.APPLY_ESCROW_FAILURE:
      return (state = {
        ...state,
        loading: false,
      });
    case trackApplicationConstants.TRACK_APPLICATION_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        track: action.payload,
      });
    case otpConstants.SEND_OTP_SUCCEESS:
      return (state = {
        ...state,
        loading: false,
        otpSent: true,
      });
    case otpConstants.OTP_VERIFY_SUCCESS:
      return (state = {
        ...state,
        loading: false,
        otpVerified: true,
      });
    case otpConstants.SET_ALL_INITIAL:
      return (state = {
        ...state,
        otpSent: false,
        otpVerified: false,
      });
    case "SET_SUCCESS_FALSE":
      return (state = {
        ...state,
        loading: false,
        success: false,
      });

    default:
      return state;
  }
};
