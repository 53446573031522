import "react-responsive-modal/styles.css";
import { Modal as ResponsiveModal } from "react-responsive-modal";

const Modal = ({ modalOpen, closeModal, children }) => {
  return (
    <ResponsiveModal
      isOpen={modalOpen}
      onClose={closeModal}
      open={modalOpen}
      center
    >
      <div
        className=" 
   modalContent"
      >
        {children}
      </div>
    </ResponsiveModal>
  );
};

export default Modal;
