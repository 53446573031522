import React from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/Vendor/DashboardLayout";

const UserSwap = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <DashboardLayout>
       <h2>Applications for crypto Swap</h2>
      <div className="items">
        <div className="buyCard">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>

          <div>Accept</div>
        </div>
        {user.sell?.map((item, index) => (
          <div key={index} className="buyCard ">
            <div>
              <strong className="key">Transaction ID:</strong>{" "}
              {item.transactionId}{" "}
            </div>
            <div>
              <strong className="key">Status:</strong> {item.status}{" "}
            </div>

            <div>
              <strong className="key">Amount:</strong>
              {item.amount}{" "}
            </div>
            <div>
              <strong className="key">Currency:</strong>
              {item.currency?.currency}{" "}
            </div>
            <div>
              <strong className="key">Quantity:</strong>
              {item.quantity.toFixed(5)}{" "}
            </div>

            <div>
              <strong className="key">Coin:</strong>
              {item.coin?.name}{" "}
            </div>
            <div>
              <strong className="key">Country:</strong> {item.country}{" "}
            </div>
            <div>
              <strong className="key">City:</strong> {item.city}{" "}
            </div>
          </div>
        ))}
      </div>
    </DashboardLayout>
  );
};

export default UserSwap;
