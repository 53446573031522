import React from 'react'
import Features from '../../components/home/Features'
import Hero from '../../components/home/Hero'
import HowWorks from '../../components/home/HowWorks'
import Intro from '../../components/home/Intro'
import PassiveIncome from '../../components/home/PassiveIncome'
import Layout from '../../components/layout'

const Home = () => {
  return (
    <div>
      <Layout>
        <Hero/>

        <Features />
        <HowWorks />
        <PassiveIncome />
        <Intro />
      </Layout>
    </div>
  )
}

export default Home