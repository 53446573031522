import React from "react";
import { useRef } from "react";

const SelfieVerify = ({ step, imageData, setimageData, videoRef }) => {
  const photoRef = useRef();

  const stop = (e) => {
    const stream = videoRef.srcObject;
    const tracks = stream.getTracks();

    for (let i = 0; i < tracks.length; i++) {
      let track = tracks[i];
      track.stop();
    }

    videoRef.srcObject = null;
  };

  const takePhoto = () => {
    let photo = photoRef.current;
    let video = videoRef.current;
    const width = 400;
    const height = width / (16 / 9);

    photo.width = width;

    photo.height = height;

    let ctx = photo.getContext("2d");

    ctx.drawImage(video, 0, 0, width, height);

    const data = photo.toDataURL("image/jpeg");
    console.log(data);
    setimageData({ ...imageData, selfie: data });
  };

  return (
    <div className="selfieContainer">
      {step == 5 && !imageData.selfie && (
        <video
          className="placeholder"
          ref={videoRef}
          style={{ border: "2px solid" }}
        ></video>
      )}
      <canvas ref={photoRef} style={{ display: "none" }}></canvas>
      {imageData?.selfie && (
        <img className="placeholder" src={imageData?.selfie} alt="" />
      )}

      <div className="btnContainer">
        {!imageData.selfie ? (
          <button className="btn btn_primary" onClick={takePhoto}>
            Take Selfie
          </button>
        ) : (
          <button
            className="btn btn_secondary"
            onClick={() => setimageData({ ...imageData, selfie: null })}
          >
            Take Again
          </button>
        )}
      </div>
    </div>
  );
};

export default SelfieVerify;
