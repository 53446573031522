import React from "react";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Password = ({ label, value, onChange, className, passwordValid }) => {
  const [hidden, sethidden] = useState(true);


  return (
    <div className={`input  ${className}`} style={{ position: "relative" }}>
      <label htmlFor=""> {label ? label : "Password "}   </label>
      <span style={{fontSize:"12px"}} >(Use uppercase letters and Symbols)</span>
      <input
        type={hidden ? "password" : "text"}
        value={value}
        onChange={onChange}
      />
      <span>{!passwordValid && "Password is not valid"} {(value && passwordValid)  && "Valid Password"} </span>
      <span
        onClick={() => sethidden(!hidden)}
        style={{ position: "absolute", right: "30px", top: "52%" }}
      >
        {hidden ? (
          <AiOutlineEyeInvisible size={26} />
        ) : (
          <AiOutlineEye size={26} />
        )}{" "}
      </span>
    </div>
  );
};

export default Password;
