import React from "react";
import "./footer.scss";
import { BsTelegram, BsTwitter, BsInstagram } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div class="custom-shape-divider-top-1666892474">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="container">
        <h2>
          Subscribe to our newsletter <img src="images/icon.png" alt="" />{" "}
        </h2>

        <div className="newsletter">
          <form action="">
            <input type="text" placeholder="Your email address" />
            <button>Sign in</button>
          </form>
        </div>

        <div className="content">
          <div className="left">
            <h3 className="gradiant_text4">Useful Links :</h3>
            <ul>
              <li>
                <Link to="/">
                  <a>Careers</a>{" "}
                </Link>
              </li>
              <li>
                <Link to="/about-us">
                  <a>About Us</a>{" "}
                </Link>
              </li>
              <li>
                <Link to="/">
                  <a>Help</a>{" "}
                </Link>
              </li>
              <li>
                <Link to="/">
                  <a>Vendor's Guide</a>{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="center">
            <h4>Available On</h4>
            <div className="socials">
              <a href="https://twitter.com/99krypto_com" target={"_blank"}>
                <BsTwitter />
              </a>
              <a href="">
                <BsInstagram />
              </a>
              <a href="https://t.me/www99kryptocom" target={"_blank"}>
                <BsTelegram />
              </a>
            </div>
          </div>
          <div className="right">
            {/* <div>
              <img src="images/whatsapp.png" alt="" />
              <span>+888 23532623626</span>
            </div> */}

            <div>
              <span>
                <AiOutlineMail size={24} />
              </span>
              <a href="mailto:Support@99krypto.com">support@99krypto.com</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
