import React from "react";
import { useSelector } from "react-redux";

const SellTrack = () => {
  const { track: cryptoToCashData } = useSelector((state) => state.service);

  return (
    <div className="track">
      <div className="info">
        <div className="key">
          <label> Status</label>
        </div>
        <div className="value">{cryptoToCashData.status}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Sell Coin</label>
        </div>
        <div className="value">{cryptoToCashData.coin?.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Sell Quantity</label>
        </div>
        <div className="value">{cryptoToCashData.quantity}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Recievable Currency</label>
        </div>
        <div className="value">{cryptoToCashData?.currency?.currency}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Recievable Amount</label>
        </div>
        <div className="value">{cryptoToCashData.amount}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Country</label>
        </div>
        <div className="value">{cryptoToCashData.country}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> City</label>
        </div>
        <div className="value">{cryptoToCashData.city}</div>
      </div>
    </div>
  );
};

export default SellTrack;
