import React from "react";
import { Link } from "react-router-dom";
import "./sidebar.scss";
import { BiMenu } from "react-icons/bi";

const Sidebar = ({ active, setactive }) => {
  return (
    <div className={`sidebar ${active && "active"}`}>
      <div className="wrapper">
        <div className="top">
          <div className="menuIcon" onClick={() => setactive(!active)}>
            <BiMenu size={32} />
          </div>
        </div>

        <div className="links">
          <ul>
          <li>
              <Link to={"/"}>
                <a className="primary" >Home</a>
              </Link>
            </li>
            <li>
              <Link to={"/cash-loans"}>
                <a>Cash Loans </a>
              </Link>
            </li>
            <li>
              <Link to={"/crypto-loans"}>
                <a>Crypto Loans </a>
              </Link>
            </li>
            <li>
              <Link to={"/deposits"}>
                <a>Deposits </a>
              </Link>
            </li>
            <li>
              <Link to={"/payment"}>
                <a>Payment </a>
              </Link>
            </li>
            <li>
              <Link to={"/buy-sell"}>
                <a>Buy/Sell </a>
              </Link>
            </li>
            <li>
              <Link to={"/swap"}>
                <a>Swap </a>
              </Link>
            </li>
            <li>
              <Link to={"/escrow"}>
                <a>Escrow </a>
              </Link>
            </li>
            <li>
              <Link to={"/stake"}>
                <a>Stake </a>
              </Link>
            </li>
            <hr />

            <li>
              <Link to={"/find-transaction"}>
                <a>Find Transaction </a>
              </Link>
            </li>
            <li>
              <Link to={"/press"}>
                <a>Press </a>
              </Link>
            </li>
            {/* <li>
              <Link to={"/white-paper"}>
                <a>White paper </a>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
