import React from "react";

const Blob2 = () => {
  return (
    <div>
      <div>
        <svg
          viewBox="0 0 800 500"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          id="blobSvg"
        >
          <g transform="translate(-268, -210)">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{ stopColor: "rgb(65, 88, 208)" }} />
                <stop offset="50%" style={{ stopColor: "rgb(200, 80, 192)" }} />
                <stop
                  offset="100%"
                  style={{ stopColor: "rgb(255, 195, 113)" }}
                ></stop>
              </linearGradient>
            </defs>
            <path
              className="blob2"
              fill="url(#gradient)"
              d="   M293,301Q191,352,196.5,259.5Q202,167,298.5,208.5Q395,250,293,301Z"
            />
          </g>
          <g transform="translate(-173, 31)">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{ stopColor: "rgb(65, 88, 208)" }} />
                <stop
                  offset="100%"
                  style={{ stopColor: "rgb(200, 80, 192)" }}
                />
              </linearGradient>
            </defs>
            <path
              className="blob2"
              fill="url(#gradient)"
              d="   M293,301Q191,352,196.5,259.5Q202,167,298.5,208.5Q395,250,293,301Z"
            />
          </g>
          <g transform="translate(-128, -212)">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{ stopColor: "rgb(65, 88, 208)" }} />
                <stop
                  offset="100%"
                  style={{ stopColor: "rgb(200, 80, 192)" }}
                />
              </linearGradient>
            </defs>
            <path
              fill="url(#gradient)"
              d="   M293,301Q191,352,196.5,259.5Q202,167,298.5,208.5Q395,250,293,301Z"
            />
          </g>
          <g transform="translate(-349, 245)">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{ stopColor: "rgb(65, 88, 208)" }} />
                <stop
                  offset="100%"
                  style={{ stopColor: "rgb(200, 80, 192)" }}
                />
              </linearGradient>
            </defs>
            <path
              fill="url(#gradient)"
              d="   M293,301Q191,352,196.5,259.5Q202,167,298.5,208.5Q395,250,293,301Z"
            />
          </g>
          <g transform="translate(-10, 8)">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{ stopColor: "rgb(65, 88, 208)" }} />
                <stop
                  offset="100%"
                  style={{ stopColor: "rgb(200, 80, 192)" }}
                />
              </linearGradient>
            </defs>
            <path
              fill="url(#gradient)"
              d="   M293,301Q191,352,196.5,259.5Q202,167,298.5,208.5Q395,250,293,301Z"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Blob2;
