import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hero from "../../components/CryptoLoans/Hero";
import Layout from "../../components/layout";
import Upload from "../../components/utils/Upload";
import Select from "../../components/utils/Select";
import Modal from "../../components/utils/Modal";
import HowItWorks from "../../components/utils/HowItWorks.jsx";
import { cryptoLoans } from "../../components/utils/howItWorks";
import { toast } from "react-toastify";
import {
  applyLend,
  otpSend,
  otpVerify,
} from "../../redux/actions/serviceAction";
import axios from "axios";
import OtpBox from "../../components/utils/OtpBox";
import { Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";

const CryptoLoans = () => {
  const dispatch = useDispatch();
  const {
    timePeriods,
    coins,
    wallets,
    success,
    otpSent,
    otpVerified,
    setting,
  } = useSelector((state) => state.service);
  const navigate = useNavigate();

  const [isCopied, setisCopied] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [howWorks, sethowWorks] = useState(false);

  const [screenShot, setscreenShot] = useState(null);

  const [cryptoWallets, setcryptoWallets] = useState([]);
  const [collateralCoinPrice, setcollateralCoinPrice] = useState("");

  const [wallet, setwallet] = useState("");
  const [otp, setotp] = useState("");
  const [periods, setperiods] = useState([]);
  // const [otpSent, setotpSent] = useState(false);
  const [addContent, setAddContent] = useState(false);

  const [loanData, setloanData] = useState({
    quantity: 0,
    lendCoin: "",
    collateralRequired: "",
    timePeriod: {
      interest: "",
    },
    totalTobePaid: "0",
    walletAddress: "",
    collateralCoin: null,
  });

  const calTotal = () => {
    if (!loanData.quantity) return;
    if (!loanData.timePeriod?.numberOfMonths) return;

    const P = loanData.quantity;
    const r = loanData?.timePeriod?.interest * 0.01;
    const t = parseInt(loanData.timePeriod?.numberOfMonths);
    const total = P * (1 + r * t);
    setloanData({
      ...loanData,
      totalTobePaid: total.toFixed(6),
    });
  };
  const formSubmit = (e) => {
    e.preventDefault();

    if (!loanData.email) {
      return toast.warn("Email is required");
    }
    if (!loanData.walletAddress) {
      return toast.warn("Wallet Address is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot Is Required");
    }
    if (!isChecked) {
      return toast.warn("Please Checkmark Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Verify your email..");
    }

    const form = new FormData();
    form.set("email", loanData.email);
    form.set("timePeriod", loanData.timePeriod._id);
    form.set("quantity", loanData.quantity);
    form.set("collateralRequired", loanData.collateralRequired);

    form.set("totalTobePaid", loanData.totalTobePaid);

    form.set("collateralCoin", loanData.collateralCoin._id);
    form.set("lendCoin", loanData.lendCoin._id);
    form.set("walletAddress", loanData.walletAddress);
    form.set("screenShot", screenShot);

    dispatch(applyLend(form));
  };

  const getCoinPrice = async (loanCoin, collateralCoin) => {
    if (!loanCoin) return;
    if (!collateralCoin) return;
    const res = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${loanCoin},${collateralCoin}&vs_currencies=usd`
    );
    const lendCoin = res.data[loanData?.lendCoin?.id];
    const _collateralCoin = res.data[loanData?.collateralCoin?.id];
    const lendCoinPrice = lendCoin["usd"];
    const collateralCoinPrice = _collateralCoin["usd"];
    const qtyPrice = loanData.quantity * lendCoinPrice;
    const percent = setting.lendCharge * 0.01;
    const d = qtyPrice / percent;
    const collateralRequired = d / collateralCoinPrice;
    setcollateralCoinPrice(parseFloat(_collateralCoin["usd"]));

    setloanData({
      ...loanData,
      collateralRequired: collateralRequired,
    });
  };

  useEffect(() => {
    calTotal();
  }, [loanData.timePeriod, loanData.quantity]);

  useEffect(() => {
    console.log(loanData.collateralCoin);
    if (!loanData.collateralCoin) return;
    const _periods = timePeriods.filter(
      (P) =>
        P.coin._id == loanData.collateralCoin?._id && P.for == "crypto-loan"
    );

    console.log(_periods);

    setperiods(_periods);
    setloanData({ ...loanData, timePeriod: { interest: "" } });
  }, [loanData.collateralCoin]);

  const fileChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    setcryptoWallets(wallets);
  }, [wallets]);

  useEffect(() => {
    setwallet(
      cryptoWallets.find(
        (wal) => wal?.coin?._id == loanData.collateralCoin?._id
      )
    );
    getCoinPrice(loanData?.lendCoin.id, loanData?.collateralCoin?.id);
  }, [loanData.collateralCoin, loanData.lendCoin, loanData.quantity]);

  const applyHandler = () => {
    if (!loanData?.lendCoin?.name) {
      return toast.warn("Please select Lend Coin");
    }
    if (!loanData.quantity) {
      return toast.warn("Please add Quantity");
    }

    if (!loanData?.collateralCoin?.name) {
      return toast.warn("Please select Collateral Coin");
    }
    if (!loanData?.timePeriod?.interest) {
      return toast.warn("Please select Time Period");
    }
    setAddContent(true);
  };

  useEffect(() => {
    if (success) {
      return navigate("/success");
    }
  }, [success]);

  const sendOtp = (e) => {
    e.preventDefault();

    dispatch(otpSend({ email: loanData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: loanData.email, otp }));
  };
  return (
    <Layout>
      <Hero />
      <Modal modalOpen={howWorks} closeModal={() => sethowWorks(false)}>
        <img
          src="../../images/howWorks/cryptoLoan.jpeg"
          alt=""
          style={{ maxWidth: "700px" }}
        />
      </Modal>
      <Slide bottom>
        <div className="formContainer">
          <div className="form">
            <Select
              options={coins}
              value={loanData.lendCoin?.name}
              label=" Loan Coin"
              property="name"
              img="253"
              onChange={(item) => {
                setloanData({ ...loanData, lendCoin: item });
              }}
            />

            <div className="input">
              <label htmlFor="">Loan Quantity</label>
              <input
                type="text"
                value={loanData.quantity}
                onChange={(e) =>
                  setloanData({
                    ...loanData,
                    quantity: e.target.value,
                  })
                }
              />
            </div>

            <Select
              label="Collateral Coin"
              value={loanData.collateralCoin?.name}
              options={coins}
              property="name"
              img="253"
              onChange={(item) => {
                setloanData({ ...loanData, collateralCoin: item });
                setwallet(wallets.find((w) => item._id === w.coin));
              }}
            />

            <div className="input">
              <label htmlFor="">Collateral required</label>
              <input
                type="text"
                value={`${
                  loanData?.collateralRequired
                    ? loanData?.collateralRequired
                    : "0"
                }`}
                disabled={true}
              />
            </div>
            <Select
              value={loanData?.timePeriod?.name}
              options={periods}
              label="Time Period"
              property="name"
              onChange={(item) =>
                setloanData({ ...loanData, timePeriod: item })
              }
            />

            <div className="input">
              <label htmlFor="">Interest Per Month</label>
              <input
                type="text"
                placeholder="%"
                value={`${loanData?.timePeriod?.interest} %`}
                disabled={true}
              />
            </div>
            <div className="input">
              <label htmlFor="">Total to be paid back</label>
              <input
                type="text"
                value={`${loanData?.totalTobePaid} ${
                  loanData?.currency?.currency
                    ? loanData?.currency?.currency
                    : ""
                }`}
                disabled={true}
              />
            </div>
            <div className="input">
              <label htmlFor="">Collateral Coin Price</label>
              <input
                type="text"
                value={`${collateralCoinPrice} USD`}
                disabled={true}
              />
            </div>
            <div className="btn_container">
              <a href="#form2">
                <button className="btn btn_secondary" onClick={applyHandler}>
                  Apply
                </button>
              </a>
            </div>
          </div>
          <div className="howWorks">
            <HowItWorks items={cryptoLoans} />
          </div>
        </div>
      </Slide>

      {addContent && (
        <div className="formContainer2">
          <div className="form2" id="form2">
            <div className="input flex2" style={{ width: "100%" }}>
              <label htmlFor="">Send Collateral Coin to</label>
              <input
                type="text"
                placeholder="wallet address....."
                value={wallet?.address}
              />
              <button className="copyButton" onClick={() => setisCopied(true)}>
                {" "}
                {isCopied ? "Copied" : "Copy"}{" "}
              </button>
            </div>

            <div className="input">
              <label htmlFor="">Email Address(Please Enter Carefully)</label>
              <input
                type="text"
                value={loanData.email}
                disabled={otpSent}
                onChange={(e) =>
                  setloanData({
                    ...loanData,
                    email: e.target.value,
                  })
                }
              />
            </div>

            <Upload onChange={fileChange} src={screenShot} />

            <div className="input full_width flex2">
              <label htmlFor="">Wallet Address to Receive Crypto</label>
              <input
                type="text"
                value={loanData.walletAddress}
                onChange={(e) =>
                  setloanData({
                    ...loanData,
                    walletAddress: e.target.value,
                  })
                }
              />
            </div>

            {otpSent && !otpVerified && (
              <OtpBox
                value={otp}
                onChange={(value) => setotp(value)}
                onClick={verifyOtp}
              />
            )}

            <div className="check ">
              <input
                type="checkbox"
                name=""
                id="check"
                onClick={() => setisChecked(!isChecked)}
              />
              <label htmlFor="check">terms & Cnditions</label>
            </div>
            <div className="btn_container  full_width">
              {otpSent ? (
                <button
                  className="btn btn_gradiant btn_large"
                  onClick={formSubmit}
                >
                  Submit Application
                </button>
              ) : (
                <button
                  className="btn btn_gradiant btn_large"
                  onClick={sendOtp}
                >
                  Send OTP
                </button>
              )}
            </div>

            <p>
              *For Quick loan repayments or part payments Contact
              Support@99krypto.com with your transaction ID and email address
            </p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CryptoLoans;
