import React from "react";
import "./styles.scss";
import { Zoom, Slide } from "react-reveal";
import { TypeAnimation } from "react-type-animation";

const Intro = () => {
  return (
    <div className="intro">
      <div className="container">
        <div className="left">
          <Slide left>
            <h2>
              99KRYPTO <br />
              makes our world <br />
              <TypeAnimation
                sequence={["   go round !", 1000, ""]}
                speed={20} // Must be in range between 1 and 99!
                wrapper="span"
                repeat={Infinity}
              />
            </h2>
          </Slide>

          {/* <div>
            <strong> Converting Designs</strong>{" "}
          </div>
          <div>
            <strong> Increased Traffics to your website</strong>{" "}
          </div> */}
        </div>

        <div className="right">
          <Zoom>
            <img src="images/search.png" alt="" />
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default Intro;
