import React from "react";
import {BsFillArrowRightCircleFill} from 'react-icons/bs'

const HowItWorks = ({ items }) => {
  return (
    <div className="howItworks">
      <h2 className="gradiant_text4" >How It Works ?</h2>
      <div className="howItWorks_items">
        {items.map((item, index) => (
          <div className="item">
            <span>STEP   <span><BsFillArrowRightCircleFill size={28} /></span> </span>  <div className="step" > {index + 1} </div>
            <p>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
