import React from "react";

const Blob1 = () => {
  return (
    <div>
      <svg
        viewBox="0 0 800 500"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        id="blobSvg"
      >
        <g transform="translate(-243, 194)">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: "rgb(200, 80, 192)" }} />
              <stop offset="100%" style={{ stopColor: "rgb(249, 113, 37)" }} />
            </linearGradient>
          </defs>
          <path
            fill="url(#gradient)"
            d="M450.5,300Q440,350,411.5,396.5Q383,443,331,466Q279,489,231.5,456.5Q184,424,140,405Q96,386,85.5,339.5Q75,293,74,249.5Q73,206,88.5,163.5Q104,121,144,98.5Q184,76,229,66Q274,56,325,62Q376,68,391,118Q406,168,433.5,209Q461,250,450.5,300Z;
  "
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Blob1;
