import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import OtpBox from "../../components/utils/OtpBox";
import Password from "../../components/utils/Password";
import Select from "../../components/utils/Select";
import { otpSend, otpVerify } from "../../redux/actions/serviceAction";
import { login, registerDealer } from "../../redux/actions/userAction";
import styles from "./styles.module.scss";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
const VendorLogin = () => {
  const dispatch = useDispatch();
  const [content, setcontent] = useState("register");
  const [passwordValid, setpasswordValid] = useState(true);

  const [screenshot, setscreenshot] = useState(null);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { otpSent, otpVerified, currencies } = useSelector(
    (state) => state.service
  );
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const [otp, setotp] = useState("");

  const [registerData, setregisterData] = useState({
    name: "",
    email: "",
    password: "",
    country: "",
    city: "",
    phoneNo: "",
    transactionId: "",
  });

  const registerSubmit = (e) => {
    e.preventDefault();

    if (!registerData.country) {
      return toast.warn("Please Select Your Country");
    }

    if (!passwordValid) {
      return toast.warn("Password is not valid.Please,use a strong password");
    }

    if (!otpVerified) {
      return toast.warn("Please Verify Your Email First");
    }

    const form = new FormData();
    form.set("name", registerData.firstName + " " + registerData.lastName);
    form.set("firstName", registerData.firstName);
    form.set("lastName", registerData.lastName);

    form.set("email", registerData.email);
    form.set("password", registerData.password);
    form.set("country", registerData.country);
    form.set("city", registerData.city);
    form.set("transactionId", registerData.transactionId);
    form.set("phoneNo", registerData.phoneNo);
    form.set("screenshot", screenshot);
    // form.set("photoId", photoId);

    // form.set("invoiceScreenshot", invoiceScreenshot);

    dispatch(registerDealer(form));
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    dispatch(login(email, password));
    setemail("");
    setpassword("");
  };

  // const fileChange = (e) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     if (reader.readyState === 2) {
  //       setphotoId(reader.result);
  //     }
  //   };
  //   reader.readAsDataURL(e.target.files[0]);
  // };

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role == "user") {
        return navigate("/account");
      } else {
        return navigate("/vendor");
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
        registerData.password
      )
    ) {
      setpasswordValid(true);
    } else {
      setpasswordValid(false);
    }
  }, [registerData.password]);

  const sendOtp = (e) => {
    e.preventDefault();

    if (!registerData.email) {
      return toast.warn("Enter your email");
    }
    dispatch(otpSend({ email: registerData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    if (!registerData.email) {
      return toast.error("Email is Required");
    }
    dispatch(otpVerify({ email: registerData.email, otp }));
  };

  return (
    <Layout>
      <div className={styles.vendorLogin}>
        <div className={styles.content}>
          <div className={styles.tabs}>
            <div
              className={`${styles.tab}  ${
                content == "register" && styles.active
              } `}
              onClick={() => setcontent("register")}
            >
              <IoMdCheckmarkCircleOutline /> Create Account
            </div>
            <div
              className={`${styles.tab}  ${
                content == "login" && styles.active
              } `}
              onClick={() => setcontent("login")}
            >
              <IoMdCheckmarkCircleOutline /> Login
            </div>
          </div>
          <div className={styles.tabContent}>
            {content == "register" ? (
              <div
                className={"formContainer2"}
                style={{ maxWidth: "100%", width: "inherit" }}
              >
                <div className={"form2"}>
                  <h1
                    className="gradiant_text full_width"
                    style={{ textAlign: "center" }}
                  >
                    Register
                  </h1>

                  <div className="input">
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      value={registerData.firstName}
                      autoComplete={false}
                      onChange={(e) =>
                        setregisterData({
                          ...registerData,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      value={registerData.lastName}
                      autoComplete={false}
                      onChange={(e) =>
                        setregisterData({
                          ...registerData,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input">
                    <label htmlFor=""> Email ( Same as provided for FD )</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setregisterData({
                          ...registerData,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="input">
                    <label htmlFor="">Phone Number</label>
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      value={registerData.phoneNo}
                      onChange={(e) =>
                        setregisterData({
                          ...registerData,
                          phoneNo: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input full_width">
                    <label htmlFor="">Fixed Deposit ID</label>
                    <input
                      type="text"
                      placeholder="Transaction ID of Fixed Deposit"
                      value={registerData.transactionId}
                      onChange={(e) =>
                        setregisterData({
                          ...registerData,
                          transactionId: e.target.value,
                        })
                      }
                    />
                  </div>

                  <Select
                    options={currencies}
                    property="country"
                    label={"Country"}
                    value={registerData.country}
                    onChange={(item) =>
                      setregisterData({
                        ...registerData,
                        country: item.country,
                      })
                    }
                  />
                  <div className="input">
                    <label htmlFor="">City</label>
                    <input
                      type="text"
                      value={registerData.city}
                      placeholder="City Name"
                      onChange={(e) =>
                        setregisterData({
                          ...registerData,
                          city: e.target.value,
                        })
                      }
                    />
                  </div>

                  <Password
                    value={registerData?.password}
                    passwordValid={passwordValid}
                    onChange={(e) =>
                      setregisterData({
                        ...registerData,
                        password: e.target.value,
                      })
                    }
                  />
                  <Password
                    label={"Confirm Password"}
                    value={registerData?.confirmPassword}
                    passwordValid={passwordValid}
                    onChange={(e) =>
                      setregisterData({
                        ...registerData,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                  {otpSent && !otpVerified && (
                    <OtpBox
                      value={otp}
                      onChange={(value) => setotp(value)}
                      onClick={verifyOtp}
                    />
                  )}
                  <div className="btnContainer full_width">
                    {!otpSent ? (
                      <button
                        style={{ margin: "20px auto" }}
                        className="btn btn_secondary btn_large"
                        onClick={sendOtp}
                      >
                        Send OTP
                      </button>
                    ) : (
                      <button
                        style={{ margin: "20px auto" }}
                        className="btn btn_secondary btn_large"
                        onClick={registerSubmit}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="formContainer2"
                style={{ maxWidth: "100%", width: "inherit" }}
              >
                <div className="form2">
                  <h1
                    className="gradiant_text full_width"
                    style={{ textAlign: "center" }}
                  >
                    Login
                  </h1>
                  <div className="input full_width">
                    <label htmlFor="">Email</label>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>

                  <Password
                    className="full_width"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  <p
                    style={{
                      padding: "0",
                      fontSize: "14px",
                      textAlign: "right",
                    }}
                  >
                    <Link
                      to="/reset-password"
                      style={{
                        padding: "0",
                        fontSize: "14px",
                        textAlign: "right",
                      }}
                    >
                      <span> Forgot Password</span>
                    </Link>
                  </p>
                  <div className="btnContainer full_width">
                    <button
                      className="btn btn_secondary"
                      onClick={loginSubmit}
                      style={{ margin: "10px auto" }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VendorLogin;
