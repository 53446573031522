import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Hero from "../../components/Deposit/Hero";
import Layout from "../../components/layout";
import HowItWorks from "../../components/utils/HowItWorks.jsx";
import { cryptoDeposit } from "../../components/utils/howItWorks";
import OtpBox from "../../components/utils/OtpBox";
import Select from "../../components/utils/Select";
import Upload from "../../components/utils/Upload";
import {
  applyDeposit,
  otpSend,
  otpVerify,
} from "../../redux/actions/serviceAction";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/utils/LoginModal";

const Deposit = () => {
  const dispatch = useDispatch();
  const [isChecked, setisChecked] = useState(false);
  const [addContent, setAddContent] = useState(false);
  const [isCopied, setisCopied] = useState("");
  const [signinModal, setsigninModal] = useState(false);

  const [otp, setotp] = useState("");
  const { coins, wallets, timePeriods, success, otpSent, otpVerified } =
    useSelector((state) => state.service);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [wallet, setwallet] = useState("");
  const [screenShot, setscreenShot] = useState(null);
  const navigate = useNavigate();

  const [periods, setperiods] = useState([]);
  const [depositData, setdepositData] = useState({
    depositingCoin: null,
    email: "",
    timePeriod: null,
    receivingAddress: "",
    totalTobeReceived: "",
  });

  useEffect(() => {
    setwallet(
      wallets.find((wal) => wal.coin?._id == depositData?.depositingCoin?._id)
    );
  }, [depositData.depositingCoin]);

  const clickHandler = (e) => {
    e.preventDefault();
    if (!depositData?.depositingCoin?.name) {
      return toast.warn("Please select Coin");
    }
    if (!depositData.amount) {
      return toast.warn("Please add amount");
    }
    if (!depositData?.timePeriod?.interest) {
      return toast.warn("Please select Time Period");
    }
    if (!depositData?.email) {
      return toast.warn("Please enter your email");
    }
    if (!isAuthenticated) {
      setsigninModal(true);
    }

    setAddContent(true);
  };

  useEffect(() => {
    if (!depositData.depositingCoin) return;
    const _periods = timePeriods.filter(
      (P) => P.coin._id == depositData.depositingCoin?._id && P.for == "deposit"
    );

    setperiods(_periods);
    setdepositData({ ...depositData, timePeriod: { interest: "" } });
  }, [depositData.depositingCoin]);

  const calTotal = () => {
    if (!depositData.amount) return;
    if (!depositData.timePeriod?.numberOfMonths) return;

    const P = parseFloat(depositData.amount).toFixed(18);
    const r = depositData?.timePeriod?.interest * 0.01;
    const t = parseInt(depositData.timePeriod?.numberOfMonths) / 12;
    const total = P * (1 + r * t);

    setdepositData({
      ...depositData,
      totalTobeReceived: total.toFixed(18),
    });
  };

  useEffect(() => {
    calTotal();
  }, [depositData.amount, depositData.timePeriod, depositData.depositingCoin]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!depositData.receivingAddress) {
      return toast.warn("Receiving Address is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please check mark the Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Please verify your email");
    }

    const form = new FormData();
    form.set("email", depositData.email);
    form.set("timePeriod", depositData.timePeriod._id);
    form.set("amount", depositData.amount);
    form.set("recieving", depositData.totalTobeReceived);
    form.set("receivingAddress", depositData.receivingAddress);
    form.set("depositingCoin", depositData.depositingCoin._id);
    form.set("screenShot", screenShot);

    dispatch(applyDeposit(form));
  };

  const fileChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (success) {
      return navigate("/success");
    }
  }, [success]);
  useEffect(() => {
    if (isAuthenticated) {
      return setsigninModal(false);
    }
  }, [isAuthenticated, user]);

  const sendOtp = (e) => {
    e.preventDefault();

    dispatch(otpSend({ email: depositData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: depositData.email, otp }));
  };

  return (
    <Layout>
      <Hero />
      <LoginModal modalOpen={signinModal} closeModal={() => null} />

      <div className="formContainer">
        <form className="form" onSubmit={clickHandler}>
          <Select
            label={"Depositing Coin"}
            value={depositData.depositingCoin?.name}
            options={coins}
            property="name"
            property2={"code"}
            img="253"
            onChange={(item) => {
              setdepositData({
                ...depositData,
                depositingCoin: item,
              });
              setwallet(wallets.find((w) => item._id === w.coin));
            }}
          />

          <div className="input">
            <label htmlFor=""> Quantity</label>
            <input
              type="text"
              placeholder="Enter quantity"
              value={depositData.amount}
              onChange={(e) =>
                setdepositData({
                  ...depositData,
                  amount: e.target.value,
                })
              }
            />
          </div>

          <Select
            label={"  Deposit Period"}
            activeOption="2 Months"
            options={periods}
            property="name"
            onChange={(item) =>
              setdepositData({ ...depositData, timePeriod: item })
            }
          />

          <div className="input">
            <label htmlFor="">Interest Percent APY</label>
            <input
              type="text"
              value={`${
                depositData.timePeriod?.interest
                  ? depositData.timePeriod?.interest
                  : ""
              }  %`}
              placeholder="Interest rate %"
              disabled
            />
          </div>
          <div className="input">
            <label htmlFor="">Email Address</label>
            <input
              type="email"
              required
              placeholder="Enter your email"
              value={depositData.email}
              disabled={otpSent}
              onChange={(e) =>
                setdepositData({
                  ...depositData,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="input">
            <label htmlFor="">Total to be Received @Maturity</label>
            <input
              type="text"
              value={depositData.totalTobeReceived}
              disabled={true}
            />
          </div>
          <div className="btn_container">
            <a href="#form2">
              <button type="submit" className="btn btn_secondary">
                Apply
              </button>
            </a>
          </div>
        </form>
        <div className="howWorks">
          <HowItWorks items={cryptoDeposit} />
        </div>
      </div>

      {addContent && (
        <div className="formContainer2">
          <div className="form2" id="form2">
            <div className="input flex2" style={{ width: "100%" }}>
              <label htmlFor=""> Recieving Adress@Maturity</label>
              <input
                placeholder="Receiving aaddress..."
                value={depositData?.receivingAddress}
                onChange={(e) =>
                  setdepositData({
                    ...depositData,
                    receivingAddress: e.target.value,
                  })
                }
              ></input>
            </div>

            <div className="input full_width">
              <label htmlFor=""> Send Crypto to</label>
              <input type="text" value={wallet?.address} disabled={true} />
              <button className="copyButton" onClick={() => setisCopied(true)}>
                {" "}
                {isCopied ? "Copied" : "Copy Address"}{" "}
              </button>
            </div>

            <Upload onChange={fileChange} src={screenShot} />

            {otpSent && !otpVerified && (
              <OtpBox
                value={otp}
                onChange={(value) => setotp(value)}
                onClick={verifyOtp}
              />
            )}

            <div className="check ">
              <input
                type="checkbox"
                name=""
                id="check"
                checked={isChecked}
                onChange={() => setisChecked(!isChecked)}
              />
              <label htmlFor="check">terms & Cnditions</label>
            </div>
            <div className="btn_container  full_width">
              {!otpSent ? (
                <button
                  className="btn btn_gradiant btn_large"
                  onClick={sendOtp}
                >
                  Send OTP
                </button>
              ) : otpVerified ? (
                <button
                  className="btn btn_gradiant btn_large"
                  onClick={formSubmit}
                >
                  Submit
                </button>
              ) : (
                <span></span>
              )}
            </div>

            <p>
              *For Quick loan repayments or part payments Contact
              Support@99krypto.com with your transaction ID and email address
            </p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Deposit;
