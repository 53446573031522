import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { kycGeneralInfo } from "../../redux/actions/userAction";
import Select from "../utils/Select";

const AccountData = ({ step, setstep }) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { otpSent, currencies } = useSelector((state) => state.service);

  const [userData, setuserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
  });

  const submitHandler = () => {
    if (!userData.country) {
      return toast.warn("Please select country");
    }
    if (!userData.city) {
      return toast.warn("City is required");
    }
    dispatch(
      kycGeneralInfo({
        ...userData,
        _id: userData._id,
        sendOtp: true,
      })
    );
  };

  useEffect(() => {
    if (otpSent) {
      setstep(step + 1);
    }
  }, [otpSent]);

  useEffect(() => {
    setuserData(user);
  }, []);

  return (
    <div className="signin">
      <div className="container">
        <div
          className="formContainer3"
          style={{ padding: "10px", maxWidth: "70vw" }}
        >
          <h2 className="secondary">KYC Registration</h2>
          <div
            className="form3"
            style={{ maxWidth: "600px", width: "80vw", padding: "10px" }}
          >
            <div className="input ">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                placeholder="first name"
                value={userData.firstName}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    firstName: e.target.value,
                  })
                }
                disabled
              />
            </div>
            <div className="input ">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                placeholder="last name"
                value={userData.lastName}
                disabled={true}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
            <div className="input ">
              <label htmlFor="">Email</label>
              <input
                type="text"
                placeholder="Your Email address"
                value={userData.email}
                autoComplete={false}
                disabled={true}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="input ">
              <label htmlFor="">Phone</label>
              <input
                type="phone"
                placeholder="Your phone number"
                value={userData.phoneNo}
                autoComplete={false}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    phoneNo: e.target.value,
                  })
                }
              />
            </div>

            <Select
              backgroundColor={"#8f4a1a"}
              options={currencies}
              property="country"
              label={"Country"}
              value={userData.country}
              onChange={(item) =>
                setuserData({
                  ...userData,
                  country: item.country,
                })
              }
            />

            <div className="input ">
              <label htmlFor="">City</label>
              <input
                type="text"
                placeholder="City Name"
                value={userData.city}
                autoComplete={false}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    city: e.target.value,
                  })
                }
              />
            </div>
            {user.role == "dealer" && (
              <div className="input full_width ">
                <label htmlFor="">Deposit ID</label>
                <input
                  type="text"
                  placeholder="Fixed Deposit Id"
                  value={userData.depositId?._id}
                  autoComplete={false}
                  disabled
                />
              </div>
            )}

            <div className="btn_container full_width">
              <button
                className="btn btn_secondary btn_large"
                onClick={submitHandler}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountData;
