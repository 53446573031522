import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Zoom } from "react-reveal";
import {BsCheck2All} from 'react-icons/bs' 

const Hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="left">
          <h2 className="gradiant_text">Deposit</h2>
     

          <h4 className="gradiant_text4">
          <span className="tick">
                  <BsCheck2All size={24} />
                </span>
            Earn Interest on Crypto Fixed Term Deposits
          </h4>
          <h4 className="gradiant_text4">
          <span className="tick">
                  <BsCheck2All size={24} />
                </span>
          Open Dealer account with your Deposit
          </h4>
          <h4 className="gradiant_text4">
          <span className="tick">
                  <BsCheck2All size={24} />
                </span>
          Interest and principal paid at settlement
          </h4>
          <h4 className="gradiant_text4">
          <span className="tick">
                  <BsCheck2All size={24} />
                </span>
          Any time loan on your Deposit Account
          </h4>
        </div>
        <div className="right">
          <Zoom>
            <LazyLoadImage src="images/deposit.png" alt="" />
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default Hero;
