import React from "react";
import { Slide } from "react-reveal";
import HowItWorks from '../../components/utils/HowItWorks.jsx'
import  { cryptoPayments } from '../../components/utils/howItWorks'
const Hero = () => {
  return (
    <div className="hero payment">
      <div className="container">
        <Slide left>
          <div className="left">
            <h2  className="gradiant_text3" >Crypto Payments</h2>

            <h3> Pay us in Crypto, we pay your Invoice in Fiat Currency</h3>
            <h3> Payments credited in 24 to 48 hours</h3>
            <h3>Fast and secure transactions</h3>
   
          </div>
        </Slide>

       <Slide right>
       <div className="right ">
          <img src="images/payment.png" alt="" />
          {/* <HowItWorks  items={cryptoPayments} /> */}
        </div>
       </Slide>
      </div>
    </div>
  );
};

export default Hero;
