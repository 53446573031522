import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const DepositTrack = () => {
  const { track: depositData } = useSelector((state) => state.service);

  return (
    <div className="track">
      <div className="info">
        <div className="key">
          <label> Status</label>
        </div>
        <div className="value">{depositData?.status}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Deposit Coin</label>
        </div>
        <div className="value">{depositData.depositingCoin?.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Deposit Quantity</label>
        </div>
        <div className="value">{depositData.amount}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Recieving at Maturity</label>
        </div>
        <div className="value">{depositData.recieving}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Deposit Maturity Date</label>
        </div>
        <div className="value">
          {moment(depositData?.depositEndDate).format(
            "MMMM Do YYYY, h:mm:ss a"
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositTrack;
