import React from "react";
import { Fade,Slide } from "react-reveal";

const HowWorks = () => {
  return (
    <div className="howWorks">
      <div className="custom-shape-divider-top-1666892304">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill" />
  </svg>
</div>

      
      <div className="container">
        <h2 className="gradiant_text2" >How The System Works</h2>
        <img src="images/info_graph.png" alt="" />

        <Slide bottom>
          <div className="items_container">
            <div className="item">
              <h4 style={{ color: "  #1D46A6" }}> CUSTOMER </h4>
              <p>
              Customers can place the orders online by sending the crypto coins to 99Krypto wallet and attain a vendor transactional OTP.
              </p>
            </div>
            <div className="item">
              <h4 style={{ color: "  #A300AD" }}> 99 KRYPTO </h4>
              <p>
              Verifies the transaction and assigns a vendor at your nearest city, vendor details are provided to you by an email.
              </p>
            </div>
            <div className="item">
              <h4 style={{ color: "  #C10032" }}> VENDOR </h4>
              <p>
              Contact the vendor , complete the transaction and after successful transaction provide the OTP to the vendor.
              </p>
            </div>
            <div className="item">
              <h4 style={{ color: "  #C46B00" }}>
    
                TRANSACTION <br /> COMPLETE{" "}
              </h4>
              <p>
              As soon as the vendor verifies with the customer provided OTP, transaction is said to be complete.
              </p>
            </div>
          </div>
        </Slide>

        <Fade right>
          <div className="counts">
            <div>
              <h2>1000</h2>
              <p>Vendors</p>
            </div>
            <div>
              <h2>60</h2>
              <p>Countries</p>
            </div>
            <div>
              <h2>45</h2>
              <p>Currencies</p>
            </div>
            <div>
              <h2>30</h2>
              <p>Crypto Assets</p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default HowWorks;
