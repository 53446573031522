import axios from "axios";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import Select from "../../components/utils/Select";
import Upload from "../../components/utils/Upload";
import OtpBox from "../../components/utils/OtpBox";
import { Slide } from "react-reveal";
import {
  applyCashToCrypto,
  applyCryptoToCash,
  otpSend,
  otpVerify,
} from "../../redux/actions/serviceAction";
import { BsCheck2All } from "react-icons/bs";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/utils/LoginModal";
const BuySell = () => {
  const dispatch = useDispatch();
  const [isCopied, setisCopied] = useState(false);
  const [signinModal, setsigninModal] = useState(false);

  const [screenShot, setscreenShot] = useState(null);
  const [screenShot2, setscreenShot2] = useState(null);
  const navigate = useNavigate();

  const [checked, setchecked] = useState("crypto");
  const [isChecked, setisChecked] = useState(false);
  const [otp, setotp] = useState("");
  const [countries, setcountries] = useState([]);
  const { coins, wallets, currencies, setting, success, otpSent, otpVerified } =
    useSelector((state) => state.service);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [wallet, setwallet] = useState("");
  const [wallet2, setwallet2] = useState("");

  const [cryptoToCashData, setcryptoToCashData] = useState({
    amount: null,
    quantity: null,
    crypto: null,
    currency: null,
    email: "",
    country: "",
    city: "",
  });

  const [cashToCryptoData, setcashToCryptoData] = useState({
    currency: null,
    amount: null,
    quantity: null,
    crypto: null,
    fee: "",
    email: "",
    country: "",
    city: "",
    email: "",
  });

  const getPrice = async (coin, currency) => {
    if (!cryptoToCashData.currency) return;
    if (!cryptoToCashData.crypto) return;
    if (!cryptoToCashData.quantity) return;
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`
    );
    const _coin = data[coin];
    const _price = parseInt(_coin[currency.toLowerCase()]);
    const price =
      _price *
      parseFloat(cryptoToCashData.quantity) *
      setting.cryptoToCashCharge *
      0.01;

    setcryptoToCashData({
      ...cryptoToCashData,
      amount: price,
    });
  };

  const getPrice2 = async (coin, currency) => {
    if (!cashToCryptoData.currency) return;
    if (!cashToCryptoData.crypto) return;
    if (!cashToCryptoData.amount) return;
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency},usd`
    );
    const _coin = data[coin];
    const _price = parseInt(_coin[currency.toLowerCase()]);
    const price =
      (cashToCryptoData.amount / _price) * setting.cashToCryptoCharge * 0.01;
    const fee = price * parseInt(_coin["usd"]) * setting.buyFee * 0.01;

    // (Receive quantity *price in usdt of that coin) *1%

    setcashToCryptoData({
      ...cashToCryptoData,
      quantity: price,
      fee: fee,
    });
  };

  useEffect(() => {
    setwallet(
      wallets.find((wal) => wal.coin?._id == cryptoToCashData?.crypto?._id)
    );
    getPrice(cryptoToCashData.crypto?.id, cryptoToCashData.currency?.currency);
  }, [
    cryptoToCashData.crypto,
    cryptoToCashData.currency,
    cryptoToCashData.quantity,
  ]);

  useEffect(() => {
    setwallet2(
      wallets.find((wal) => wal.coin?._id == cashToCryptoData?.crypto?._id)
    );
    getPrice2(
      cashToCryptoData.crypto?.id,
      cashToCryptoData.currency?.currency.toLowerCase()
    );
  }, [
    cashToCryptoData.crypto,
    cashToCryptoData.currency,
    cashToCryptoData.amount,
  ]);

  const submitCryptoToCash = (e) => {
    e.preventDefault();
    console.log(cryptoToCashData);
    if (!cryptoToCashData.email) {
      return toast.warn("Email Is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please check mark  Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Please Verify Your Email");
    }

    const form = new FormData();
    form.set("email", cryptoToCashData.email);
    form.set("amount", cryptoToCashData.amount);
    form.set("quantity", cryptoToCashData.quantity);
    form.set("country", cryptoToCashData.country);
    form.set("city", cryptoToCashData.city);
    form.set("coin", cryptoToCashData.crypto?._id);
    form.set("currency", cryptoToCashData.currency?._id);
    form.set("screenShot", screenShot);

    dispatch(applyCryptoToCash(form));

    setcryptoToCashData({
      amount: null,
      quantity: null,
      crypto: null,
      currency: null,
      email: "",
      country: "",
      city: "",
    });
  };

  const submitCashToCrypto = (e) => {
    e.preventDefault();
    if (!screenShot2) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please check mark the Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Please Verify Your Email");
    }

    const form = new FormData();
    form.set("email", cashToCryptoData.email);
    form.set("amount", cashToCryptoData.amount);
    form.set("quantity", cashToCryptoData.quantity);
    form.set("country", cashToCryptoData.country);
    form.set("city", cashToCryptoData.city);
    form.set("coin", cashToCryptoData.crypto?._id);
    form.set("currency", cashToCryptoData.currency?._id);
    form.set("usdtFee", cashToCryptoData.fee);
    form.set("screenShot", screenShot2);

    dispatch(applyCashToCrypto(form));

    setcashToCryptoData({
      amount: null,
      quantity: null,
      crypto: null,
      currency: null,
      email: "",
      country: "",
      city: "",
    });
  };

  const fileChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const fileChange2 = (e) => {
    const file = e.target.files[0];
    const mb = 1024 * 1023;
    if (file.size > mb) {
      return toast.warn("File is above 1 Mb,Compress and try again");
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setscreenShot2(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const addContentHandler = () => {
    if (!cryptoToCashData.crypto) {
      return toast.warn("Please select coin");
    }
    if (!cryptoToCashData.quantity) {
      return toast.warn("Please add an quantity");
    }
    if (!cryptoToCashData.currency) {
      return toast.warn("Please select currency");
    }
  };
  const addContent2Handler = () => {
    if (!cashToCryptoData.currency) {
      return toast.warn("Please select currency");
    }
    if (!cashToCryptoData.amount) {
      return toast.warn("Please add an amount");
    }
    if (!cashToCryptoData.crypto) {
      return toast.warn("Please select coin");
    }
  };

  useEffect(() => {
    if (success) {
      return navigate("/success");
    }
  }, [success]);

  useEffect(() => {
    if (isAuthenticated) {
      return setsigninModal(false);
    }
  }, [isAuthenticated, user]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (checked == "crypto") {
      if (!cryptoToCashData.email) {
        return toast.warn("Enter your email");
      }
      dispatch(otpSend({ email: cryptoToCashData.email }));
    } else if (checked == "cash") {
      if (!cashToCryptoData.email) {
        return toast.warn("Enter your email");
      }
      dispatch(otpSend({ email: cashToCryptoData.email }));
    }
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    if (checked == "crypto") {
      if (!cryptoToCashData.email) {
        return toast.error("Email is Required");
      }
      dispatch(otpVerify({ email: cryptoToCashData.email, otp }));
    } else if (checked == "cash") {
      if (!cashToCryptoData.email) {
        return toast.error("Email is Required");
      }
      dispatch(otpVerify({ email: cashToCryptoData.email, otp }));
    }
  };

  const applyHandler = () => {
    if (!isAuthenticated) {
      setsigninModal(true);
    }
  };

  useEffect(() => {
    if (checked == "crypto") {
      if (cryptoToCashData?.currency?.currency) {
        setcountries(
          currencies.filter(
            (currency) =>
              currency.currency == cryptoToCashData.currency.currency
          )
        );
      }
    }
    if (checked == "cash") {
      if (cashToCryptoData?.currency?.currency) {
        setcountries(
          currencies.filter(
            (currency) =>
              currency.currency == cashToCryptoData.currency?.currency
          )
        );
      }
    }
  }, [cryptoToCashData.currency, cashToCryptoData.currency]);
  return (
    <Layout>
      <LoginModal modalOpen={signinModal} closeModal={() => null} />
      <div className="buy_sell">
        <div className="switcher">
          <div
            className={`buy ${checked == "crypto" && "active"} `}
            onClick={() => setchecked("crypto")}
            active={checked === "crypto"}
          >
            Sell Crypto
          </div>
          <div
            className={`sell ${checked == "cash" && "active"} `}
            onClick={() => setchecked("cash")}
          >
            Buy Crypto
          </div>
        </div>
        {checked == "crypto" ? (
          <Slide left>
            <div className="formContainer3">
              <h3>Sell your Crypto</h3>
              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Cash out your Crypto with our Dealers
              </h4>
              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Your Crypto is secure with us till you get Cash
              </h4>
              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Our Dealers are bonded with Crypto deposits
              </h4>
              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Most Secure P2D(Person to Dealer) transactions
              </h4>
              <div
                className="form3"
                style={{ maxWidth: "700px", margin: "0 auto" }}
              >
                <Select
                  label="Crypto"
                  backgroundColor="#8f4a1a"
                  disabled={checked !== "crypto"}
                  value={cryptoToCashData.crypto?.name}
                  options={coins}
                  property="code"
                  img="243"
                  onChange={(item) => {
                    setcryptoToCashData({
                      ...cryptoToCashData,
                      crypto: item,
                    });
                  }}
                />
                <div className="input">
                  <label htmlFor="">Quantity</label>
                  <input
                    type="text"
                    placeholder="Quantity"
                    disabled={checked !== "crypto"}
                    value={cryptoToCashData.quantity}
                    onChange={(e) =>
                      setcryptoToCashData({
                        ...cryptoToCashData,
                        quantity: e.target.value,
                      })
                    }
                  />
                </div>
                <Select
                  label="Currency"
                  backgroundColor="#8f4a1a"
                  disabled={checked !== "crypto"}
                  value={cryptoToCashData?.currency?.currency}
                  options={currencies}
                  property2="currency"
                  property="country"
                  onChange={(item) =>
                    setcryptoToCashData({
                      ...cryptoToCashData,
                      currency: item,
                    })
                  }
                />
                <div className="input">
                  <label htmlFor="">Amount</label>
                  <input
                    type="text"
                    placeholder="Amount.."
                    value={cryptoToCashData.amount}
                    disabled={true}
                  />
                </div>

                <div className="input">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    placeholder="Enter Your Email.."
                    value={cryptoToCashData.email}
                    disabled={otpSent}
                    onChange={(e) =>
                      setcryptoToCashData({
                        ...cryptoToCashData,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <Select
                  backgroundColor="#8f4a1a"
                  label="Recieving Country"
                  value={cryptoToCashData.country}
                  options={countries}
                  property="country"
                  onChange={(item) =>
                    setcryptoToCashData({
                      ...cryptoToCashData,
                      country: item.country,
                    })
                  }
                />
                <div className="input full_width">
                  <label htmlFor=""> Receiving City(Optional)</label>
                  <input
                    type="text"
                    placeholder="City Name.."
                    value={cryptoToCashData.city}
                    onChange={(e) =>
                      setcryptoToCashData({
                        ...cryptoToCashData,
                        city: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="input full_width">
                  <label htmlFor=""> Send Crypto to</label>
                  <input
                    type="text"
                    style={{ fontSize: "14px" }}
                    value={wallet?.address}
                    disabled
                  />
                  <CopyToClipboard
                    text={wallet?.address}
                    onCopy={() => setisCopied(true)}
                  >
                    <button className="copyButton">
                      {" "}
                      {isCopied ? "Copied" : "Copy"}{" "}
                    </button>
                  </CopyToClipboard>
                </div>

                <Upload full={true} onChange={fileChange} src={screenShot} />

                {otpSent && !otpVerified && (
                  <OtpBox
                    value={otp}
                    onChange={(value) => setotp(value)}
                    onClick={verifyOtp}
                  />
                )}

                <div className="check ">
                  <input
                    type="checkbox"
                    name=""
                    id="check"
                    onChange={() => setisChecked(!isChecked)}
                  />
                  <label htmlFor="check">terms & Cnditions</label>
                </div>
                {!isAuthenticated ? (
                  <div className="btn_container full_width">
                    <button
                      className="btn btn_secondary btn_large"
                      onClick={applyHandler}
                    >
                      Apply
                    </button>
                  </div>
                ) : (
                  <div className="btn_container full_width">
                    {!otpSent ? (
                      <button
                        className="btn btn_secondary btn_large"
                        onClick={sendOtp}
                      >
                        Send OTP
                      </button>
                    ) : (
                      <button
                        className="btn btn_secondary btn_large"
                        onClick={submitCryptoToCash}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>{" "}
          </Slide>
        ) : (
          <Slide right>
            <div className="formContainer3 buy">
              <h3>Buy</h3>

              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Buy your Crypto with Cash from our Dealers{" "}
              </h4>
              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Secure Transactions, Dealers are bonded with Crypto deposits for
                your saftey
              </h4>
              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Fast and Secure P2D(Person to Dealer) transactions{" "}
              </h4>
              <h4 className="gradiant_text">
                <span className="tick">
                  <BsCheck2All size={24} />
                </span>
                Pay fee in USDT/USDC and get dealer details to your Email
              </h4>
              <div
                className="form3"
                style={{ maxWidth: "700px", margin: "0 auto" }}
              >
                <Select
                  label="Currency"
                  backgroundColor="#8f4a1a"
                  disabled={checked !== "cash"}
                  value={cashToCryptoData?.currency?.currency}
                  options={currencies}
                  property2="currency"
                  property="country"
                  onChange={(item) =>
                    setcashToCryptoData({
                      ...cashToCryptoData,
                      currency: item,
                    })
                  }
                />
                <div className="input">
                  <label htmlFor="">Amount</label>
                  <input
                    disabled={checked !== "cash"}
                    type="text"
                    name="Currency"
                    placeholder="Amount.."
                    value={cashToCryptoData.amount}
                    onChange={(e) =>
                      setcashToCryptoData({
                        ...cashToCryptoData,
                        amount: e.target.value,
                      })
                    }
                  />
                </div>
                <Select
                  label="Coin"
                  backgroundColor="#8f4a1a"
                  disabled={checked !== "cash"}
                  value={cashToCryptoData.crypto?.name}
                  options={coins}
                  property="code"
                  img="243"
                  onChange={(item) => {
                    setcashToCryptoData({
                      ...cashToCryptoData,
                      crypto: item,
                    });
                  }}
                />
                <div className="input">
                  <label htmlFor=""> Receiving quantity</label>
                  <input
                    type="text"
                    name="Loan Amount"
                    id="Loan Amount"
                    className="inp_control"
                    value={cashToCryptoData.quantity}
                    disabled
                    onChange={(e) =>
                      setcashToCryptoData({
                        ...cashToCryptoData,
                        quantity: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="input">
                  <label htmlFor=""> Fee (USDT/USDC)</label>
                  <input
                    type="text"
                    name="Fee (USDT/USDC)"
                    id="Fee (USDT/USDC)"
                    className="inp_control"
                    disabled
                    placeholder="Fee.."
                    value={cashToCryptoData.fee}
                    onChange={(e) =>
                      setcashToCryptoData({
                        ...cashToCryptoData,
                        fee: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="input">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    className="inp_control"
                    placeholder="Enter your Email..."
                    value={cashToCryptoData.email}
                    disabled={otpSent}
                    onChange={(e) =>
                      setcashToCryptoData({
                        ...cashToCryptoData,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <Select
                  backgroundColor="#8f4a1a"
                  label="Recieving Country"
                  value={cashToCryptoData.country}
                  options={countries}
                  property="country"
                  onChange={(item) =>
                    setcashToCryptoData({
                      ...cashToCryptoData,
                      country: item.country,
                    })
                  }
                />
                <div className="input">
                  <label htmlFor=""> Receiving City(Optional)</label>
                  <input
                    type="text"
                    value={cashToCryptoData?.city}
                    onChange={(e) =>
                      setcashToCryptoData({
                        ...cashToCryptoData,
                        city: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="input full_width">
                  <label htmlFor=""> Send USDT/USDC to</label>
                  <input
                    type="text"
                    style={{ fontSize: "14px" }}
                    value={wallet2?.address}
                    disabled
                  />
                  <CopyToClipboard
                    text={wallet?.address}
                    onCopy={() => setisCopied(true)}
                  >
                    <button className="copyButton">
                      {" "}
                      {isCopied ? "Copied" : "Copy"}{" "}
                    </button>
                  </CopyToClipboard>
                </div>

                <Upload onChange={fileChange2} src={screenShot2} />

                {otpSent && !otpVerified && (
                  <OtpBox
                    value={otp}
                    onChange={(value) => setotp(value)}
                    onClick={verifyOtp}
                  />
                )}

                <div className="check ">
                  <input
                    type="checkbox"
                    name=""
                    id="check"
                    onChange={() => setisChecked(!isChecked)}
                  />
                  <label htmlFor="check">terms & Cnditions</label>
                </div>

                <div className="btn_container full_width">
                  {!otpSent ? (
                    <button
                      className="btn btn_secondary btn_large"
                      onClick={sendOtp}
                    >
                      Send OTP
                    </button>
                  ) : (
                    <button
                      className="btn btn_secondary btn_large"
                      onClick={submitCashToCrypto}
                    >
                      Submit
                    </button>
                  )}
                </div>

                <p className="text-white">
                  *For assistance or enquiries please contact
                  support@99krypto.com with your email and transaction ID
                </p>
              </div>
            </div>
          </Slide>
        )}
      </div>
    </Layout>
  );
};

export default BuySell;
