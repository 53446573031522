export const cryptoLoansConstants = {
  GET_CRYPTO_LOANS_REQUEST: "GET_CRYPTO_LOANS_REQUEST",
  GET_CRYPTO_LOANS_SUCCESS: "GET_CRYPTO_LOANS_SUCCESS",
  GET_CRYPTO_LOANS_FAILURE: "GET_CRYPTO_LOANS_FAILURE",

  UPDATE_CRYPTO_LOAN_REQUEST: "UPDATE_CRYPTO_LOAN_REQUEST",
  UPDATE_CRYPTO_LOAN_SUCCESS: "UPDATE_CRYPTO_LOAN_SUCCESS",
  UPDATE_CRYPTO_LOAN_FAILURE: "UPDATE_CRYPTO_LOAN_FAILURE",
};

export const cryptodEPOSITSConstants = {
  GET_CRYPTO_DEPOSIT_REQUEST: "GET_CRYPTO_DEPOSIT_REQUEST",
  GET_CRYPTO_DEPOSIT_SUCCESS: "GET_CRYPTO_DEPOSIT_SUCCESS",
  GET_CRYPTO_DEPOSIT_FAILURE: "GET_CRYPTO_DEPOSIT_FAILURE",

  UPDATE_DEPOSIT_REQUEST: "UPDATE_DEPOSIT_REQUEST",
  UPDATE_DEPOSIT_SUCCESS: "UPDATE_DEPOSIT_SUCCESS",
  UPDATE_DEPOSIT_FAILURE: "UPDATE_DEPOSIT_FAILURE",
};

export const cryptoToCashConstants = {
  GET_CRYPTO_TO_CASH_REQUEST: "GET_CRYPTO_TO_CASH_REQUEST",
  GET_CRYPTO_TO_CASH_SUCCESS: "GET_CRYPTO_TO_CASH_SUCCESS",
  GET_CRYPTO_TO_CASH_FAILURE: "GET_CRYPTO_TO_CASH_FAILURE",

  APPLY_CRYPTO_TO_CASH_REQUEST: "APPLY_CRYPTO_TO_CASH_REQUEST",
  APPLY_CRYPTO_TO_CASH_SUCCESS: "APPLY_CRYPTO_TO_CASH_SUCCESS",
  APPLY_CRYPTO_TO_CASH_FAILURE: "APPLY_CRYPTO_TO_CASH_FAILURE",

  GET_CASH_TO_CRYPTO_REQUEST: "GET_CASH_TO_CRYPTO_REQUEST",
  GET_CASH_TO_CRYPTO_SUCCESS: "GET_CASH_TO_CRYPTO_SUCCESS",
  GET_CASH_TO_CRYPTO_FAILURE: "GET_CASH_TO_CRYPTO_FAILURE",
};

export const cryptoCoinConstants = {
  GET_CRYPTO_COINS_REQUEST: "GET_CRYPTO_COINS_REQUEST",
  GET_CRYPTO_COINS_SUCCESS: "GET_CRYPTO_COINS_REQUEST",
  GET_CRYPTO_COINS_FAILURE: "GET_CRYPTO_COINS_REQUEST",

  GET_CRYPTO_PRICE_REQUEST: "GET_CRYPTO_PRICE_REQUEST",
  GET_CRYPTO_PRICE_SUCCESS: "GET_CRYPTO_PRICE_SUCCESS",
  GET_CRYPTO_PRICE_FAILURE: "GET_CRYPTO_PRICE_FAILURE",
};

export const currencyConstants = {
  GET_CURRENCY_REQUEST: "GET_CURRENCY_REQUEST",
  GET_CURRENCY_SUCCESS: "GET_CURRENCY_REQUEST",
  GET_CURRENCY_FAILURE: "GET_CURRENCY_REQUEST",
};

export const timePeriodConstants = {
  GET_TIME_PERIODS_REQUEST: "GET_TIME_PERIODS_REQUEST",
  GET_TIME_PERIODS_SUCCESS: "GET_TIME_PERIODS_REQUEST",
  GET_TIME_PERIODS_FAILURE: "GET_TIME_PERIODS_REQUEST",
};

export const applyLoanConstants = {
  APPLY_LOAN_REQUEST: "APPLY_LOAN_REQUEST",
  APPLY_LOAN_SUCCESS: "APPLY_LOAN_SUCCESS",
  APPLY_LOAN_FAILURE: "APPLY_LOAN_FAILURE",
};

export const lendConatants = {
  APPLY_LEND_REQUEST: "APPLY_LEND_REQUEST",
  APPLY_LEND_SUCCESS: "APPLY_LEND_SUCCESS",
  APPLY_LEND_FAILURE: "APPLY_LEND_FAILURE",
};

export const depositConstants = {
  APPLY_DEPOSIT_REQUEST: "APPLY_DEPOSIT_REQUEST",
  APPLY_DEPOSIT_SUCCESS: "APPLY_DEPOSIT_SUCCESS",
  APPLY_DEPOSIT_FAILURE: "APPLY_DEPOSIT_FAIL",
};

export const walletConstants = {
  GET_WALLETS_REQUEST: "GET_WALLETS_REQUEST",
  GET_WALLETS_SUCCESS: "GET_WALLETS_SUCCESS",
  GET_WALLETS_FAILURE: "GET_WALLETS_FAILURE",
};

export const cashToCryptoConstants = {
  APPLY_CASH_TO_CRYPTO_REQUEST: "APPLY_CASH_TO_CRYPTO_REQUEST",
  APPLY_CASH_TO_CRYPTO_SUCCESS: "APPLY_CASH_TO_CRYPTO_SUCCESS",
  APPLY_CASH_TO_CRYPTO_FAILURE: "APPLY_CASH_TO_CRYPTO_FAILURE",
};

export const cryptoSwapConstants = {
  APPLY_CRYPTO_SWAP_REQUEST: "APPLY_CRYPTO_SWAP_REQUEST",
  APPLY_CRYPTO_SWAP_SUCCESS: "APPLY_CRYPTO_SWAP_SUCCESS",
  APPLY_CRYPTO_SWAP_FAILURE: "APPLY_CRYPTO_SWAP_FAILURE",
};

export const paymentConstants = {
  APPLY_PAYMENT_REQUEST: "APPLY_PAYMENT_REQUEST",
  APPLY_PAYMENT_SUCCESS: "APPLY_PAYMENT_SUCCESS",
  APPLY_PAYMENT_FAILURE: "APPLY_PAYMENT_FAILURE",
};

export const escrowConstants = {
  APPLY_ESCROW_REQUEST: "APPLY_ESCROW_REQUEST",
  APPLY_ESCROW_SUCCESS: "APPLY_ESCROW_SUCCESS",
  APPLY_ESCROW_FAILURE: "APPLY_ESCROW_FAILURE",
};

export const settingConstants = {
  GET_SETTING_REQUEST: "GET_SETTING_REQUEST",
  GET_SETTING_SUCCESS: "GET_SETTING_SUCCESS",
  GET_SETTING_FAILURE: "GET_SETTING_FAILURE",
};

export const otpConstants = {
  SEND_OTP_REQUEST: "SEND_OTP_REQUEST",
  SEND_OTP_SUCCEESS: "SEND_OTP_SUCCEESS",
  SEND_OTP_FAILURE: "SEND_OTP_FAILURE",

  OTP_VERIFY_REQUEST: "OTP_VERIFY_REQUEST",
  OTP_VERIFY_SUCCESS: "OTP_VERIFY_SUCCESS",
  OTP_VERIFY_FAILURE: "OTP_VERIFY_FAILURE",
  SET_ALL_INITIAL: "SET_ALL_INITIAL",
};

export const trackApplicationConstants = {
  TRACK_APPLICATION_REQUEST: "TRACK_APPLICATION_REQUEST",
  TRACK_APPLICATION_SUCCESS: "TRACK_APPLICATION_SUCCESS",
  TRACK_APPLICATION_FAILURE: "TRACK_APPLICATION_FAILURE",
};

export const dealerConstants = {
  GET_CRYPTO_BUY_REQUEST: "GET_CRYPTO_BUY_REQUEST",
  GET_CRYPTO_BUY_SUCCESS: "GET_CRYPTO_BUY_SUCCESS",
  GET_CRYPTO_BUY_FAILURE: "GET_CRYPTO_BUY_FAILURE",

  GET_CRYPTO_SELL_REQUEST: "GET_CRYPTO_SELL_REQUEST",
  GET_CRYPTO_SELL_SUCCESS: "GET_CRYPTO_SELL_SUCCESS",
  GET_CRYPTO_SELL_FAILURE: "GET_CRYPTO_SELL_FAILURE",

  GET_CASH_LOANS_REQUEST: "GET_CASH_LOANS_REQUEST",
  GET_CASH_LOANS_SUCCESS: "GET_CASH_LOANS_SUCCESS",
  GET_CASH_LOANS_FAILURE: "GET_CASH_LOANS_FAILURE",

  GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAILURE: "GET_PAYMENTS_FAILURE",

  TAKE_LOAN_ORDER_REQUEST: "TAKE_LOAN_ORDER_REQUEST",
  TAKE_LOAN_ORDER_SUCCESS: "TAKE_LOAN_ORDER_SUCCESS",
  TAKE_LOAN_ORDER_FAILURE: "TAKE_LOAN_ORDER_FAILURE",

  TAKE_PAYMENT_ORDER_REQUEST: "TAKE_PAYMENT_ORDER_REQUEST",
  TAKE_PAYMENT_ORDER_SUCCESS: "TAKE_PAYMENT_ORDER_SUCCESS",
  TAKE_PAYMENT_ORDER_FAILURE: "TAKE_PAYMENT_ORDER_FAILURE",

  GET_TAKEN_LOAN_ORDER_REQUEST: "GET_TAKEN_LOAN_ORDER_REQUEST",
  GET_TAKEN_LOAN_ORDER_SUCCESS: "GET_TAKEN_LOAN_ORDER_SUCCESS",
  GET_TAKEN_LOAN_ORDER_FAILURE: "GET_TAKEN_LOAN_ORDER_FAILURE",

  GET_TAKEN_PAYMENT_ORDER_REQUEST: "GET_TAKEN_PAYMENT_ORDER_REQUEST",
  GET_TAKEN_PAYMENT_ORDER_SUCCESS: "GET_TAKEN_PAYMENT_ORDER_SUCCESS",
  GET_TAKEN_PAYMENT_ORDER_FAILURE: "GET_TAKEN_PAYMENT_ORDER_FAILURE",

  ACCEPT_BUY_REQUEST: "ACCEPT_BUY_REQUEST",
  ACCEPT_BUY_SUCCESS: "ACCEPT_BUY_SUCCESS",
  ACCEPT_BUY_FAILURE: "ACCEPT_BUY_FAILURE",

  ACCEPT_SELL_REQUEST: "ACCEPT_SELL_REQUEST",
  ACCEPT_SELL_SUCCESS: "ACCEPT_SELL_SUCCESS",
  ACCEPT_SELL_FAILURE: "ACCEPT_SELL_FAILURE",

  GET_ACCEPTED_BUY_REQUEST: "GET_ACCEPTED_BUY_REQUEST",
  GET_ACCEPTED_BUY_SUCCESS: "GET_ACCEPTED_BUY_SUCCESS",
  GET_ACCEPTED_BUY_FAILURE: "GET_ACCEPTED_BUY_FAILURE",

  GET_ACCEPTED_SELL_REQUEST: "GET_ACCEPTED_SELL_REQUEST",
  GET_ACCEPTED_SELL_SUCCESS: "GET_ACCEPTED_SELL_SUCCESS",
  GET_ACCEPTED_SELL_FAILURE: "GET_ACCEPTED_SELL_FAILURE",

  MARK_LOAN_ORDER_SUCCESS_REQUEST: "MARK_LOAN_ORDER_SUCCESS_REQUEST",
  MARK_LOAN_ORDER_SUCCESS_SUCCESS: "MARK_LOAN_ORDER_SUCCESS_SUCCESS",
  MARK_LOAN_ORDER_SUCCESS_FAILURE: "MARK_LOAN_ORDER_SUCCESS_FAILURE",

  MARK_PAYMENT_ORDER_SUCCESS_REQUEST: "MARK_PAYMENT_ORDER_SUCCESS_REQUEST",
  MARK_PAYMENT_ORDER_SUCCESS_SUCCESS: "MARK_PAYMENT_ORDER_SUCCESS_SUCCESS",
  MARK_PAYMENT_ORDER_SUCCESS_FAILURE: "MARK_PAYMENT_ORDER_SUCCESS_FAILURE",
};



export const transactionConstants={
  GET_CUSTOMER_TRANSACTIONS_REQUEST:"GET_CUSTOMER_TRANSACTIONS_REQUEST",
  GET_CUSTOMER_TRANSACTIONS_SUCCESS:"GET_CUSTOMER_TRANSACTIONS_SUCCESS",
  GET_CUSTOMER_TRANSACTIONS_FAILURE:"GET_CUSTOMER_TRANSACTIONS_FAILURE",
}