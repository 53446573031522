import React from "react";
import { useSelector } from "react-redux";

const BuyTrack = () => {
  const { track: cashToCryptoData } = useSelector((state) => state.service);

  return (
    <div className="track">
      <div className="info">
        <div className="key">
          <label> Status</label>
        </div>
        <div className="value">{cashToCryptoData.status}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Buy Coin</label>
        </div>
        <div className="value">{cashToCryptoData.coin?.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Buy Quantity</label>
        </div>
        <div className="value">{cashToCryptoData.quantity}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Payable Currency</label>
        </div>
        <div className="value">{cashToCryptoData?.currency?.currency}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Payable Amount</label>
        </div>
        <div className="value">{cashToCryptoData.amount}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Country</label>
        </div>
        <div className="value">{cashToCryptoData.country}</div>
      </div>
    </div>
  );
};

export default BuyTrack;
