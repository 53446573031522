import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import Hero from "../../components/Payment/Hero";
import Select from "../../components/utils/Select";
import Upload from "../../components/utils/Upload";
import OtpBox from "../../components/utils/OtpBox";
import {
  applyPayment,
  otpSend,
  otpVerify,
} from "../../redux/actions/serviceAction";
import { Slide } from "react-reveal";
import { cryptoPayments } from "../../components/utils/howItWorks";
import HowItWorks from "../../components/utils/HowItWorks.jsx";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/utils/LoginModal";

const Payment = () => {
  const dispatch = useDispatch();
  const { currencies, coins, wallets, setting, success, otpSent, otpVerified } =
    useSelector((state) => state.service);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [isCopied, setisCopied] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [signinModal, setsigninModal] = useState(false);

  const [coinPrice, setcoinPrice] = useState(null);
  const [wallet, setwallet] = useState(null);
  const [paymentScreenshot, setpaymentScreenshot] = useState(null);
  const [invoiceScreenshot, setinvoiceScreenshot] = useState(null);
  const [otp, setotp] = useState("");

  const [countries, setcountries] = useState([]);

  const navigate = useNavigate();

  const [paymentData, setpaymentData] = useState({
    senderEmail: "",
    recieverEmail: "",
    currency: null,
    coin: null,
    amount: "",
    totalTobePaid: "",
    bankDetails: "",
    paymentMode: "",
    country: "",
    city: "",
  });

  const calTotal = (data) => {
    if (!paymentData.amount) return;

    const P = parseInt(paymentData.amount);
    const r = setting.paymentCharge * 0.01;
    const coin = data[paymentData?.coin?.id];

    const coin_price = parseFloat(
      coin[paymentData.currency?.currency.toLowerCase()]
    );
    console.log(coin_price);
    const total = (P / coin_price) * r;

    setpaymentData({
      ...paymentData,
      totalTobePaid: total.toFixed(18),
    });
  };

  useEffect(() => {
    setwallet(wallets.find((wal) => wal?.coin?._id == paymentData.coin?._id));
    getCoinPrice(
      paymentData?.coin?.id,
      paymentData?.currency?.currency.toLowerCase()
    );
  }, [paymentData.coin, paymentData.currency, paymentData.amount]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!paymentData.senderEmail) {
      return toast.warn("Sender Email is required");
    }
    if (!paymentData.amount) {
      return toast.warn("Amount is required");
    }
    if (!paymentData.recieverEmail) {
      return toast.warn("Receiver's Email is required");
    }
    if (!paymentData.currency) {
      return toast.warn("Please Select Currency");
    }
    if (!paymentData.coin) {
      return toast.warn("Please Select Crypto/Coin");
    }
    if (!paymentData.bankDetails) {
      return toast.warn("Bank Details is required");
    }
    if (!paymentData.paymentMode) {
      return toast.warn("Please Select Payment Mode");
    }
    if (!paymentScreenshot) {
      return toast.warn("Payment Screenshot is required");
    }
    if (!otpVerified) {
      return toast.warn("Please verify sender email");
    }

    const form = new FormData();
    form.set("senderEmail", paymentData.senderEmail);
    form.set("recieverEmail", paymentData.recieverEmail);
    form.set("currency", paymentData.currency?._id);
    form.set("coin", paymentData.coin?._id);
    form.set("amount", paymentData.amount);
    form.set("totalTobePaid", paymentData.totalTobePaid);
    form.set("bankDetails", paymentData.bankDetails);
    form.set("paymentMode", paymentData.paymentMode);
    form.set("country", paymentData.paymentMode);
    form.set("city", paymentData.city);
    form.set("paymentScreenshot", paymentScreenshot);
    form.set("invoiceScreenshot", invoiceScreenshot);

    dispatch(applyPayment(form));
  };

  const getCoinPrice = async (coin, currency) => {
    if (!currency) return;
    if (!coin) return;
    try {
      const res = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`
      );

      setcoinPrice(res.data);
      calTotal(res.data);
    } catch (error) {}
  };

  const fileChange = (e) => {
    console.log("ddd");
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    // if (e.target.name == "file1") {
    //   reader.onload = () => {
    //     if (reader.readyState === 2) {
    //       setpaymentScreenshot(reader.result);
    //     }
    //   };
    // } else {
    reader.onload = () => {
      if (reader.readyState === 2) {
        setpaymentScreenshot(reader.result);

        console.log(paymentScreenshot);
      }
      // };
    };
  };

  useEffect(() => {
    if (success) {
      return navigate("/success");
    }
  }, [success]);
  useEffect(() => {
    if (paymentData.currency) {
      const items = currencies.filter(
        (c) => c.currency == paymentData.currency?.currency
      );
      setcountries(items);
    }
  }, [paymentData.currency]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      return setsigninModal(true);
    }

    if (!paymentData.senderEmail) {
      return toast.error("Sender's Email is required");
    }

    dispatch(otpSend({ email: paymentData.senderEmail }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: paymentData.senderEmail, otp }));
  };
  return (
    <Layout>
      <Hero />
      <LoginModal modalOpen={signinModal} closeModal={() => null} />

      <div className="formContainer">
        <Slide bottom>
          <div className="form">
            <div className="input">
              <label htmlFor="">Sender Email</label>
              <input
                type="text"
                value={paymentData.senderEmail}
                disabled={otpVerified}
                placeholder="Enter sender's email address"
                onChange={(e) =>
                  setpaymentData({
                    ...paymentData,
                    senderEmail: e.target.value,
                  })
                }
              />
            </div>
            <div className="input">
              <label htmlFor="">Reciever’s Email</label>
              <input
                type="text"
                placeholder="Enter reciever's email address"
                value={paymentData.recieverEmail}
                onChange={(e) =>
                  setpaymentData({
                    ...paymentData,
                    recieverEmail: e.target.value,
                  })
                }
              />
            </div>
            <Select
              value={paymentData?.currency?.currency}
              options={currencies}
              property2="currency"
              label={"Currency"}
              property="country"
              onChange={(item) =>
                setpaymentData({
                  ...paymentData,
                  currency: item,
                })
              }
            />
            <div className="input">
              <label htmlFor="">Amount</label>
              <input
                type="text"
                placeholder="Enter amount"
                value={paymentData.amount}
                onChange={(e) =>
                  setpaymentData({
                    ...paymentData,
                    amount: e.target.value,
                  })
                }
              />
            </div>
            <Select
              value={paymentData.coin?.name}
              label="Crypto"
              options={coins}
              property="name"
              property2={"code"}
              img="253"
              onChange={(item) => {
                setpaymentData({ ...paymentData, coin: item });
              }}
            />
            <div className="input">
              <label htmlFor=""> To be paid</label>
              <input type="text" value={paymentData.totalTobePaid} disabled />
            </div>
            <Select
              label={"  Payment Mode"}
              activeOption="Normal"
              options={[
                { name: "WU payment", mode: "wu_payment" },
                { name: "Bank Payment", mode: "bank_payment" },
              ]}
              value={paymentData.paymentMode}
              property="name"
              onChange={(item) =>
                setpaymentData({
                  ...paymentData,
                  paymentMode: item.mode,
                })
              }
            />
            <Select
              label={"Country"}
              value={paymentData.country}
              options={countries}
              property="country"
              onChange={(item) =>
                setpaymentData({
                  ...paymentData,
                  country: item.country,
                })
              }
            />

            <div className="input full_width">
              <label htmlFor="">City</label>
              <input
                type="text"
                placeholder="Your City Name"
                value={paymentData.city}
                onChange={(e) =>
                  setpaymentData({
                    ...paymentData,
                    city: e.target.value,
                  })
                }
              />
            </div>

            <div className="input full_width">
              <label htmlFor=""> Send Crypto to</label>
              <input
                type="text"
                placeholder="1000.00"
                value={wallet?.address ? wallet.address : "Select Coin first"}
                disabled
                style={{ fontSize: "14px" }}
              />
            </div>

            <div className="input full_width">
              <label htmlFor="">WU Details/Bank Details</label>
              <textarea
                className="textarea"
                style={{ height: "100px" }}
                name=""
                id=""
                placeholder="WU Details/Bank Details"
                value={paymentData.bankDetails}
                onChange={(e) =>
                  setpaymentData({
                    ...paymentData,
                    bankDetails: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <Upload
              onChange={fileChange}
              src={paymentScreenshot}
              name={"file1"}
            />

            <div className="check ">
              <input
                type="checkbox"
                name=""
                id="check"
                onChange={() => setisChecked(!isChecked)}
              />
              <label htmlFor="check">terms & Cnditions</label>
            </div>

            {otpSent && !otpVerified && (
              <OtpBox
                value={otp}
                onChange={(value) => setotp(value)}
                onClick={verifyOtp}
              />
            )}
            <div className="btn_container ">
              {!otpSent ? (
                <button
                  className="btn btn_secondary btn_large"
                  onClick={sendOtp}
                >
                  Send OTP
                </button>
              ) : (
                <button
                  className="btn btn_secondary btn_large"
                  onClick={formSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </Slide>
        <div className="howWorks">
          <HowItWorks items={cryptoPayments} />
        </div>
      </div>
    </Layout>
  );
};

export default Payment;
