import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AccountData from "../../components/KYC/AccountData";
import OtpVerify from "../../components/KYC/OtpVerify";
import PhotoVerifications from "../../components/KYC/PhotoVerifications";
import SelectCardType from "../../components/KYC/PhotoVerifications/SelectCardType";
import Layout from "../../components/layout";
import "./style.scss";

const KYC = () => {
  const [step, setstep] = useState(0);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [userData, setuserData] = useState(null);
  const [imageData, setimageData] = useState({
    cardType: "",
    idFront: null,
    idBack: null,
    selfie: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/signin");
    } else if (user.kyc_submitted) {
    }
  }, [isAuthenticated]);
  useEffect(() => {
    setuserData(user);
  }, []);

  const getComponent = () => {
    if (user.kyc_verified) {
      return (
        <div>
          <h2 className="gradiant_text3" style={{ textAlign: "center" }}>
            You are verified
          </h2>
          <div className="btn_container">
            <Link to="/account">
              {" "}
              <button className="btn btn_secondary">
                Visit your dashboard
              </button>{" "}
            </Link>
          </div>
        </div>
      );
    } else if (user.kyc_submitted && !user.kyc_verified) {
      return (
        <h2 className="gradiant_text1" style={{ textAlign: "center" }}>
          Your KYC verification is under progress
        </h2>
      );
    } else if (step == 0) {
      return <AccountData step={step} setstep={setstep} />;
    } else if (step == 1) {
      return <OtpVerify step={step} setstep={setstep} />;
    } else if (step == 2) {
      return (
        <SelectCardType
          step={step}
          setstep={setstep}
          imageData={imageData}
          setimageData={setimageData}
        />
      );
    } else if (step >= 3) {
      return (
        <PhotoVerifications
          step={step}
          setstep={setstep}
          userData={userData}
          imageData={imageData}
          setimageData={setimageData}
        />
      );
    }
  };

  return (
    <Layout>
      <div className="kyc">{getComponent()}</div>
    </Layout>
  );
};

export default KYC;
