import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import { resetPassword } from "../../redux/actions/userAction";
import { Slide } from "react-reveal";

const ResetPassword = () => {
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const dispatch = useDispatch();
  const params = useParams();

  const applyHandler = () => {
    if (!password) {
      return toast.warn("Email is required");
    }

    if (password !== confirmPassword) {
      return toast.warn("Please confirm password");
    }

    const form = new FormData();

    form.set("password", password);
    form.set("confirmPassword", confirmPassword);

    dispatch(resetPassword(params.token, form));
  };

  return (
    <Layout>
      <div>
        <div className="formContainer3">
          <h2>Reset Password</h2>
          <Slide bottom>
            <div className="form3" style={{ width: "600px" }}>
              <div className="input full_width">
                <label htmlFor=""> Enter new password</label>
                <input
                  type="text"
                  placeholder="Password... "
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
              </div>
              <div className="input full_width">
                <label htmlFor="">New Password</label>
                <input
                  type="text"
                  placeholder="Confirm Password "
                  value={confirmPassword}
                  onChange={(e) => setconfirmPassword(e.target.value)}
                />
              </div>
              <div className="btn_container full_width">
                <button className="btn btn_secondary" onClick={applyHandler}>
                  Change Password{" "}
                </button>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
