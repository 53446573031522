import axios from "../utils/backend_api";
import { backend_api } from "../utils/backend_api";
import { toast } from "react-toastify";
import {
  applyLoanConstants,
  cashToCryptoConstants,
  cryptoCoinConstants,
  cryptoSwapConstants,
  cryptoToCashConstants,
  currencyConstants,
  depositConstants,
  escrowConstants,
  lendConatants,
  otpConstants,
  paymentConstants,
  settingConstants,
  timePeriodConstants,
  trackApplicationConstants,
  transactionConstants,
  walletConstants,
} from "../constants/serviceConstants";

export const getCryptoCoins = () => async (dispatch) => {
  try {
    dispatch({ type: cryptoCoinConstants.GET_CRYPTO_COINS_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.get(
      `${backend_api}/api/v1/coin`,

      config
    );

    dispatch({
      type: cryptoCoinConstants.GET_CRYPTO_COINS_SUCCESS,
      payload: data.coins,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: cryptoCoinConstants.GET_CRYPTO_COINS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getCurrency = () => async (dispatch) => {
  try {
    dispatch({ type: currencyConstants.GET_CURRENCY_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/currency`);

    dispatch({
      type: currencyConstants.GET_CURRENCY_SUCCESS,
      payload: data.currencies,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: currencyConstants.GET_CURRENCY_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getTimePeriods = () => async (dispatch) => {
  try {
    dispatch({ type: timePeriodConstants.GET_TIME_PERIODS_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/time_period`);

    dispatch({
      type: timePeriodConstants.GET_TIME_PERIODS_SUCCESS,
      payload: data.timePeriods,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: timePeriodConstants.GET_TIME_PERIODS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getWallets = () => async (dispatch) => {
  try {
    dispatch({ type: walletConstants.GET_WALLETS_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/wallets`);

    dispatch({
      type: walletConstants.GET_WALLETS_SUCCESS,
      payload: data.wallets,
    });
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: walletConstants.GET_WALLETS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyLoan = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: applyLoanConstants.APPLY_LOAN_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/apply_loan`,

      loanData,

      config
    );

    dispatch({
      type: applyLoanConstants.APPLY_LOAN_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
    // window.location.pathname = "/success";
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: applyLoanConstants.APPLY_LOAN_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyLend = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: lendConatants.APPLY_LEND_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/apply_lend`,

      loanData,

      config
    );

    dispatch({
      type: lendConatants.APPLY_LEND_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: lendConatants.APPLY_LEND_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyDeposit = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: depositConstants.APPLY_DEPOSIT_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/deposit`,

      loanData,

      config
    );

    dispatch({
      type: depositConstants.APPLY_DEPOSIT_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: depositConstants.APPLY_DEPOSIT_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyCryptoToCash = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: cryptoToCashConstants.APPLY_CRYPTO_TO_CASH_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/crypto_to_cash`,

      loanData,

      config
    );

    dispatch({
      type: cryptoToCashConstants.APPLY_CRYPTO_TO_CASH_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: cryptoToCashConstants.APPLY_CRYPTO_TO_CASH_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyCashToCrypto = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: cashToCryptoConstants.APPLY_CASH_TO_CRYPTO_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/cash_to_crypto`,

      loanData,

      config
    );

    dispatch({
      type: cashToCryptoConstants.APPLY_CASH_TO_CRYPTO_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
    window.location.pathname = "/success";
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: cashToCryptoConstants.APPLY_CASH_TO_CRYPTO_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyCryptoSwap = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: cryptoSwapConstants.APPLY_CRYPTO_SWAP_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/crypto_swap`,

      loanData,

      config
    );

    dispatch({
      type: cryptoSwapConstants.APPLY_CRYPTO_SWAP_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: cryptoSwapConstants.APPLY_CRYPTO_SWAP_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyPayment = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: paymentConstants.APPLY_PAYMENT_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/payment`,

      loanData,

      config
    );

    dispatch({
      type: paymentConstants.APPLY_PAYMENT_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
    window.location.pathname = "/success";
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: paymentConstants.APPLY_PAYMENT_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const applyEscrow = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: escrowConstants.APPLY_ESCROW_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/escrow`,

      loanData,

      config
    );

    dispatch({
      type: escrowConstants.APPLY_ESCROW_SUCCESS,
      payload: data,
    });
    toast.success("Application Submitted");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: escrowConstants.APPLY_ESCROW_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getSetting = () => async (dispatch) => {
  try {
    dispatch({ type: settingConstants.GET_SETTING_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/setting`);

    dispatch({
      type: settingConstants.GET_SETTING_SUCCESS,
      payload: data.setting,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: settingConstants.GET_SETTING_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const setSuccessFalse = () => async (dispatch) => {
  try {
    dispatch({ type: "SET_SUCCESS_FALSE" });
  } catch (error) {}
};

export const otpSend = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: otpConstants.SEND_OTP_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/otp`,

      loanData,

      config
    );

    dispatch({
      type: otpConstants.SEND_OTP_SUCCEESS,
      payload: data,
    });
    toast.success("OTP sent Successfully(Check your email)");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: otpConstants.SEND_OTP_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const otpVerify = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: otpConstants.OTP_VERIFY_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.put(
      `${backend_api}/api/v1/verify_otp`,

      loanData,

      config
    );

    dispatch({
      type: otpConstants.OTP_VERIFY_SUCCESS,
      payload: data,
    });
    toast.success("OTP Verified Successfully");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: otpConstants.OTP_VERIFY_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const setInitial = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: otpConstants.SET_ALL_INITIAL });
  } catch (error) {}
};

export const trackApplication = (loanData) => async (dispatch) => {
  console.log(loanData);
  try {
    dispatch({ type: trackApplicationConstants.TRACK_APPLICATION_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/track_application`,

      loanData,

      config
    );

    dispatch({
      type: trackApplicationConstants.TRACK_APPLICATION_SUCCESS,
      payload: data.application,
    });
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: otpConstants.OTP_VERIFY_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getTransactions = () => async (dispatch) => {
  try {
    dispatch({ type: transactionConstants.GET_CUSTOMER_TRANSACTIONS_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.get(`${backend_api}/api/v1/transactions`);

    dispatch({
      type: transactionConstants.GET_CUSTOMER_TRANSACTIONS_SUCCESS,
      payload: data.application,
    });
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: transactionConstants.GET_CUSTOMER_TRANSACTIONS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};
