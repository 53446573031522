import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { kycRegister } from "../../../redux/actions/userAction";
import BackCardVerify from "./BackCardVerify";
import FrontCardVerify from "./FrontCardVerify";
import SelectCardType from "./SelectCardType";
import SelfieVerify from "./SelfieVerify";
import "./style.scss";

const PhotoVerifications = ({
  step,
  setstep,
  userData,
  children,
  imageData,
  setimageData,
}) => {
  const [facingMode, setfacingMode] = useState("environment");

  const dispatch = useDispatch();
  const videoRef = useRef();

  useEffect(() => {
    getVideo("environment");
  }, [videoRef]);

  const getVideo = (mode, ratio) => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        video: {
          facingMode: mode,
          aspectRatio: 16 / 9,
        },
      })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.onloadedmetadata = function (e) {
          video.play();
        };
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  useEffect(() => {
    if (step == 5) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          video: {
            facingMode: "user",
            aspectRatio: 1 / 1,
          },
        })
        .then((stream) => {
          let video = videoRef.current;
          video.srcObject = stream;
          video.onloadedmetadata = function (e) {
            video.play();
          };
        })
        .catch((err) => {
          console.error("error:", err);
        });
    }
  }, [step]);

  const getComponent = () => {
    switch (step) {
      case 3:
        return (
          <FrontCardVerify
            imageData={imageData}
            setimageData={setimageData}
            videoRef={videoRef}
          />
        );
        break;
      case 4:
        return (
          <BackCardVerify
            imageData={imageData}
            setimageData={setimageData}
            videoRef={videoRef}
          />
        );
        break;
      case 5:
        return (
          <SelfieVerify
            imageData={imageData}
            setimageData={setimageData}
            videoRef={videoRef}
            step={step}
          />
        );
        break;

      default:
        break;
    }
  };

  const stop = (e) => {
    const stream = videoRef.srcObject;
    const tracks = stream.getTracks();

    for (let i = 0; i < tracks.length; i++) {
      let track = tracks[i];
      track.stop();
    }

    videoRef.srcObject = null;
  };

  const submitHandler = () => {
    const form = new FormData();

    form.append("idFront", imageData.idFront);
    form.append("idBack", imageData.idBack);
    form.append("selfie", imageData.selfie);
    form.append("_id", userData._id);
    form.append("email", userData.email);

    dispatch(kycRegister(form));
    stop();
  };

  return (
    <div className="card-verify">
      <div className="photo-taker">
        <div style={{ display: step == 5 ? "none" : "" }}>
          <video
            ref={videoRef}
            ratio={1 / 1}
            className="card-placeholder"
            style={{ border: "2px solid" }}
          ></video>
        </div>
      </div>

      {getComponent()}

      <div className="col-8 m-auto">
        {step == 5 && imageData?.selfie ? (
          <button className="btn btn_primary " onClick={submitHandler}>
            Submit
          </button>
        ) : (
          <button
            className="btn btn_primary "
            onClick={() => setstep(step + 1)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default PhotoVerifications;
