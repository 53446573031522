import React from "react";
import OtpInput from "react-otp-input";

const OtpBox = ({ value, onChange, onClick }) => {
  return (
      <div className="input full_width  otp_input_container">
        <label
          className="text-white mb-1 fw600"
          htmlFor="Email Address(Please Enter Carefully)"
        >
          Enter your OTP
        </label>
        <br />
        <OtpInput
          value={value}
          onChange={onChange}
          numInputs={4}
          separator={<span>--</span>}
          className="otp_input"
          placeholder="_"
        />
        <div >
        <label htmlFor=""></label>
        <br />
        <button className="btn btn_primary" onClick={onClick}>
          Verify OTP
        </button>
      </div>
      </div>
      
  );
};

export default OtpBox;
