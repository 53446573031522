import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
  injected: {
    display: {
      // logo: "https://trustwallet.com/assets/images/media/assets/TWT.png",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png",
      name: "Metamask",
      description: "Connect with Metamask Wallet",
    },
    package: null,
  },

  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Web 3 Modal Demo", // Required
      infuraId: "effceaf92f93404295b3896ddfb24ed1", // Required unless you provide a JSON RPC url; see `rpc` below
    },
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "effceaf92f93404295b3896ddfb24ed1", // required
      qrcodeModalOptions: {
        desktopLinks: [
          "metamask",
          "metamask",
          "metamask",
          "trust",
          "ledger",
          "wallet",
        ],
        mobileLinks: ["metamask", "trust"],
      },
    },
  },

  binancechainwallet: {
    package: true,
    options: {
      infuraId: "effceaf92f93404295b3896ddfb24ed1", // required
    },
  },
};
