import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/Vendor/DashboardLayout";
import { logout, updateProfile } from "../../redux/actions/userAction";

const VendorProfile = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [userData, setuserData] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/signin");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setuserData(user);
  }, [user]);

  const update = () => {
    dispatch(updateProfile({ ...userData, depositId: userData._id }));
  };

  return (
    <DashboardLayout  vendor={true} >
      <div>
        <div
          className="formContainer3"
          style={{ width: "800px", margin: "0 auto" }}
        >
            <h2>Your Profile</h2>

          <div className="form3">
            <div className="input">
              <label htmlFor=""> First Name</label>
              <input type="text" disabled value={user?.firstName} />
            </div>
            <div className="input">
              <label htmlFor=""> Last Name</label>
              <input
                type="text"
                placeholder="Your Name"
                disabled
                value={user?.lastName}
              />
            </div>
            <div className="input">
              <label htmlFor="">Email</label>
              <input type="text" value={user?.email} disabled />
            </div>
            <div className="input">
              <label htmlFor=""> Phone Number</label>
              <input
                type="text"
                value={userData?.phoneNo}
                onChange={(e) =>
                  setuserData({ ...userData, phoneNo: e.target.value })
                }
              />
            </div>
            <div className="input">
              <label htmlFor=""> Country</label>
              <input type="text" disabled={true} value={user?.country} />
            </div>
            <div className="input">
              <label htmlFor=""> City</label>
              <input type="text" disabled={true} value={user?.city} />
            </div>
            <div className="input">
              <label htmlFor=""> Fixed Deposit No</label>
              <input type="text" disabled={true} value={user?.depositId?._id} />
            </div>
            <div className="input">
              <label htmlFor=""> Deposit Amount</label>
              <input
                type="text"
                disabled={true}
                value={user?.depositId?.amount?.toString()}
              />
            </div>
            <div className="btn_container"></div>
            <div className="btn_container">
              <Link to="/change-password">
                <button className="btn btn_gradiant btn_large">
                  Change Password
                </button>
              </Link>
            </div>
            <div className="btn_container">
              <button
                onClick={() => dispatch(logout())}
                className="btn btn_secondary btn_large"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default VendorProfile;
