import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import Hero from "../../components/Escrow/Hero";
import Upload from "../../components/utils/Upload";
import { toast } from "react-toastify";
import {
  applyEscrow,
  otpSend,
  otpVerify,
} from "../../redux/actions/serviceAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "../../components/utils/Select";
import CopyToClipboard from "react-copy-to-clipboard";
import OtpBox from "../../components/utils/OtpBox";
import HowItWorks from "../../components/utils/HowItWorks.jsx";
import { escrows } from "../../components/utils/howItWorks";
const Escrow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [wallet, setwallet] = useState("");
  const [isCopied, setisCopied] = useState(false);

  const [recieverWallet, setrecieverWallet] = useState(null);
  const [isChecked, setisChecked] = useState(false);
  const [otp, setotp] = useState("");

  const {
    currencies,
    timePeriods,
    coins,
    wallets,
    loading,
    setting,
    success,
    otpSent,
    otpVerified,
  } = useSelector((state) => state.service);
  const [quantity, setquantity] = useState(0);

  const [screenshot, setscreenshot] = useState(null);

  const [escrowData, setescrowData] = useState({
    email1: "",
    email2: "",
    party1: "",
    party2: "",
    coin: null,
    quantity: null,
    description: "",
    recieverWallet: "",
    crypto: "",
    totalTobePaid: 0,
  });

  useEffect(() => {
    setescrowData({
      ...escrowData,
      totalTobePaid: quantity * parseInt(setting?.escrowCharge) * 0.01,
    });
  }, [quantity]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!escrowData.email1) {
      return toast.warn("Email1 is required");
    }
    if (!escrowData.email2) {
      return toast.warn("Email2 is required");
    }
    if (!quantity) {
      return toast.warn("Quantity is required");
    }
    if (!escrowData.coin) {
      return toast.warn("Please Select Crypto/Coin");
    }
    if (!escrowData.recieverWallet) {
      return toast.warn("Please Select Crypto/Coin");
    }

    if (!screenshot) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please Check Mark Terms & Conditions");
    }

    const form = new FormData();
    form.set("email1", escrowData.email1);
    form.set("email2", escrowData.email2);
    form.set("party1", escrowData.party1);
    form.set("party2", escrowData.party2);
    form.set("quantity", quantity);
    form.set("coin", escrowData.coin?._id);
    form.set("description", escrowData.description);
    form.set("recieverWallet", escrowData.recieverWallet);
    form.set("totalTobePaid", escrowData.totalTobePaid);
    form.set("screenshot", screenshot);
    // form.set("invoiceScreenshot", invoiceScreenshot);

    dispatch(applyEscrow(form));
  };

  useEffect(() => {
    setwallet(wallets.find((wal) => wal?.coin?._id == escrowData.coin?._id));
  }, [escrowData.coin]);

  const fileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setscreenshot(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (success) {
      return navigate("/success");
    }
  }, [success]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (!escrowData.email1) {
      return toast.error("Email is required");
    }

    dispatch(otpSend({ email: escrowData.email1 }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: escrowData.email1, otp }));
  };

  return (
    <Layout>
      <Hero />

      <div className="formContainer">
        <div className="form">
          <Select
            label={"Party 1"}
            value={escrowData.party1}
            options={[
              { name: "Buyer", value: "buyer" },
              { name: "Seller", value: "seller" },
            ]}
            property="name"
            onChange={(item) =>
              setescrowData({
                ...escrowData,
                party1: item.value,
                party2: item.value == "buyer" ? "seller" : "buyer",
              })
            }
          />
          <div className="input">
            <label htmlFor=""> Email Address (Party 1)</label>
            <input
              type="text"
              placeholder="Email address of party 1"
              value={escrowData.email1}
              onChange={(e) =>
                setescrowData({
                  ...escrowData,
                  email1: e.target.value,
                })
              }
            />
          </div>

          <div className="input">
            <label htmlFor="">Party 2</label>
            <input type="text" value={escrowData.party2} disabled />
          </div>
          <div className="input">
            <label htmlFor=""> Email Address (Party 2)</label>
            <input
              type="text"
              placeholder="Email address of party 2"
              value={escrowData.email2}
              onChange={(e) =>
                setescrowData({
                  ...escrowData,
                  email2: e.target.value,
                })
              }
            />
          </div>

          <div className="input">
            <label htmlFor="">Quantity</label>
            <input
              type="text"
              value={quantity}
              placeholder="Enater quantity"
              onChange={(e) => setquantity(e.target.value)}
            />
          </div>

          <Select
            label="Crypto"
            value={escrowData.coin?.name}
            options={coins}
            property="name"
            property2={"code"}
            img="253"
            onChange={(item) => {
              setescrowData({ ...escrowData, coin: item });
              setwallet(wallets.find((w) => item._id === w.coin));
            }}
          />

          <div className="input full_width">
            <label htmlFor="">Description of Agreement</label>
            <textarea
              className="textarea"
              style={{ height: "100px" }}
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Description of Agreement...."
              value={escrowData.description}
              onChange={(e) =>
                setescrowData({
                  ...escrowData,
                  description: e.target.value,
                })
              }
            ></textarea>
          </div>
          <div className="input">
            <label htmlFor="">Fee %</label>
            <input
              type="text"
              placeholder="%"
              value={`${setting?.escrowCharge - 100} %`}
              disabled
            />
          </div>

          <div className="input">
            <label htmlFor="">Total Pay</label>
            <input
              type="text"
              placeholder="Total..."
              value={escrowData.totalTobePaid}
              disabled
            />
          </div>

          <div className="input full_width">
            <label htmlFor="">Receiver's Wallet Address</label>
            <input
              type="text"
              placeholder="Reciever's wallet address(Enter carefully)"
              value={escrowData.recieverWallet}
              onChange={(e) =>
                setescrowData({
                  ...escrowData,
                  recieverWallet: e.target.value,
                })
              }
            />
          </div>

          <div className="input full_width">
            <label htmlFor="">Now Send your Crypto to</label>
            <input
              type="text"
              placeholder="Wallet Address"
              style={{ fontSize: "14px" }}
              value={wallet?.address ? wallet?.address : "Select Coin"}
              disabled
            />
            <CopyToClipboard
              text={wallet?.address}
              onCopy={() => setisCopied(true)}
            >
              <button className="copyButton">
                {isCopied ? "Copied" : "Copy Address"}
              </button>
            </CopyToClipboard>
          </div>

          <Upload onChange={fileChange} src={screenshot} />

          {otpSent && !otpVerified && (
            <OtpBox
              value={otp}
              onChange={(value) => setotp(value)}
              onClick={verifyOtp}
            />
          )}

          <div className="check ">
            <input
              type="checkbox"
              name=""
              id="check"
              onChange={() => setisChecked(!isChecked)}
            />
            <label htmlFor="check">terms & Cnditions</label>
          </div>

          <div className="btn_container ">
            {!otpSent ? (
              <button className="btn btn_secondary btn_large" onClick={sendOtp}>
                Send OTP
              </button>
            ) : (
              <button
                className="btn btn_secondary btn_large"
                onClick={formSubmit}
              >
                Submit
              </button>
            )}
          </div>

          <p>
            *For assistance or enquiries contact Support@99krypto.com with your
            transaction ID and email address
          </p>
        </div>
        <div className="howWorks">
          <HowItWorks items={escrows} />
        </div>
      </div>
    </Layout>
  );
};

export default Escrow;
