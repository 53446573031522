import { dealerConstants } from "../constants/serviceConstants";

const initState = {
  buy: [],
  sell: [],
  loans: [],
  payments:[],
  acceptedSell: [],
  acceptedBuy: [],
  takenLoan: [],
};

export const dealerReducer = (state = initState, action) => {
  switch (action.type) {
    case dealerConstants.GET_CRYPTO_BUY_SUCCESS:
      return (state = {
        ...state,
        buy: action.payload,
      });
    case dealerConstants.GET_CRYPTO_SELL_SUCCESS:
      return (state = {
        ...state,
        sell: action.payload,
      });
    case dealerConstants.GET_CASH_LOANS_SUCCESS:
      return (state = {
        ...state,
        loans: action.payload,
      });
      case dealerConstants.GET_PAYMENTS_SUCCESS:
        return (state = {
          ...state,
          payments: action.payload,
        });
    case dealerConstants.GET_ACCEPTED_BUY_SUCCESS:
      return (state = {
        ...state,
        acceptedBuy: action.payload,
      });

    case dealerConstants.GET_ACCEPTED_SELL_SUCCESS:
      return (state = {
        ...state,
        acceptedSell: action.payload,
      });

    case dealerConstants.GET_TAKEN_LOAN_ORDER_SUCCESS:
      return (state = {
        ...state,
        takenLoan: action.payload,
      });
      case dealerConstants.GET_TAKEN_PAYMENT_ORDER_SUCCESS:
        return (state = {
          ...state,
          takenPayment: action.payload,
        });

    default:
      return state;
  }
};
