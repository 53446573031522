export const cashLoans = [
  "Apply Loan",
  "Contact Vendor ",
  "Provide OTP",
  "Get Cash",
  "Transaction Complete",
];

export const cryptoLoans = [
  "Apply Loan",
  "Transfer Collateral  ",
  "Receive Crypto Loan",
  "Transaction Complete",
];

export const cryptoDeposit = [
  "Fill Application ",
  "Transfer Depositing Crypto  ",
  "Provide Your Wallet ",
  "Get Transaction Email ",
  " Transaction Complete",
];

export const cryptoPayments = [
  "Fill the form",
  "Send crypto ",
  "Payment processed to Payee ",
  "Receive receipt(Email)",
  "Transaction complete",
];

export const escrows = [
  "Fill the form",
  "Send crypto ",
  "Seller met Preset Conditions ",
  "Seller Raise Payment Request ",
  ".99krypto verifies with Buyer & Release Fund",
];
