import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../components/Vendor/DashboardLayout";
import {
  acceptSell,
  getAcceptedSell,
  getCryptoSell,
} from "../../redux/actions/dealerAction";

const VendorSell = () => {
  const dispatch = useDispatch();
  const { sell, acceptedSell } = useSelector((state) => state.dealer);

  const { user } = useSelector((state) => state.user);

  const [securityCode, setsecurityCode] = useState("");

  useEffect(() => {
    dispatch(getCryptoSell());
    dispatch(getAcceptedSell());
  }, []);

  const clickHandler = (id) => {
    dispatch(acceptSell(id, { securityCode }));

    setsecurityCode("");
  };

  const isBlocked = (item) => {
    const length = item?.tried.filter((i) => i == user._id).length;

    return length >= 3 ? true : false;
  };

  return (
    <DashboardLayout vendor={true}>
      <h2>Accepted by you</h2>
      <div className="items">
        <div className="buyCard owned">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>
        </div>
        {acceptedSell?.map((item, index) => (
          <div key={index} className="buyCard owned">
            <div>
              <span className="key">Transaction ID</span>{" "}
              <span>{item.transactionId}</span>{" "}
            </div>
            <div>
              <span className="key">Status</span>{" "}
              <span className={item.status}>{item.status} </span>
            </div>

            <div>
              <span className="key">Amount</span> <span>{item.amount}</span>{" "}
            </div>
            <div>
              <span className="key">Currency</span>{" "}
              <span>{item.currency?.currency}</span>{" "}
            </div>
            <div>
              <span className="key">Quantity</span>{" "}
              <span> {item.quantity}</span>{" "}
            </div>

            <div>
              <span className="key">Coin</span> <span>{item.coin?.name}</span>{" "}
            </div>
            <div>
              <span className="key">Country</span> <span>{item.country}</span>{" "}
            </div>
            <div>
              <span className="key">City:</span> <span>{item.city}</span>{" "}
            </div>
          </div>
        ))}
      </div>
      <h2>New Arrivals</h2>
      <div className="items">
        <div className="buyCard">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>

          <div>Accept</div>
        </div>
        {sell?.map((item, index) => (
          <div key={index} className="buyCard ">
            <div>
              {" "}
              <strong className="key">Transaction ID:</strong>{" "}
              {item.transactionId}{" "}
            </div>
            <div>
              {" "}
              <strong className="key">Status:</strong> {item.status}{" "}
            </div>

            <div>
              <strong className="key">Amount:</strong>
              {item.amount}{" "}
            </div>
            <div>
              <strong className="key">Currency:</strong>
              {item.currency?.currency}{" "}
            </div>
            <div>
              <strong className="key">Quantity:</strong>
              {item.quantity.toFixed(5)}{" "}
            </div>

            <div>
              <strong className="key">Coin:</strong>
              {item.coin?.name}{" "}
            </div>
            <div>
              <strong className="key">Country:</strong> {item.country}{" "}
            </div>
            <div>
              <strong className="key">City:</strong> {item.city}{" "}
            </div>
            {isBlocked(item) ? (
              <div>
                {" "}
                <button className="btn btn-danger">Blocked</button>{" "}
              </div>
            ) : (
              <div>
                <input
                  value={securityCode}
                  type="number"
                  placeholder="Security Code"
                  onChange={(e) => setsecurityCode(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => clickHandler(item._id)}
                >
                  Accept
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </DashboardLayout>
  );
};

export default VendorSell;
