import React from 'react'
import Layout from '../../components/layout'
import styles from './styles.module.scss'

const Stake = () => {
  return (
  <Layout>
  <div className={`container ${styles.stake} `}>
  <h1  className='gradiant_text' >Coming Soon !</h1>
  </div>
  </Layout>
  )
}

export default Stake