import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import { login } from "../../redux/actions/userAction";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [userData, setuserData] = useState({
    email: "",
    password: "",
  });

  const submitHandler = () => {
    if (!userData.email || !userData.password) {
      return toast.warn("All fields are required");
    }

    dispatch(login(userData.email, userData.password));
  };

  useEffect(() => {
    if (isAuthenticated && !user.kyc_submitted) {
      return navigate("/kyc");
    }
    if (isAuthenticated) {
      if (user?.role == "user") {
        return navigate("/account");
      } else {
        return navigate("/vendor");
      }
    }
  }, [isAuthenticated, user]);

  return (
    <Layout>
      <div className="signin">
        <div className="container">
          <img src="images/signin.png" alt="" />
          <div className="formContainer3">
            <h2 className="secondary">Sign In</h2>
            <div className="form3" style={{ maxWidth: "600px" }}>
              <div className="input full_width">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Your Email"
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      email: e.target.value,
                    })
                  }
                />
              </div>

              <div className="input full_width">
                <label htmlFor="">Password</label>
                <input
                  type="text"
                  placeholder="password..."
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      password: e.target.value,
                    })
                  }
                />
              </div>
              <span>
                <Link to={"/reset-password"}>
                  <a>Forgot Password?</a>{" "}
                </Link>
              </span>
              <div className="btn_container full_width">
                <button
                  className="btn btn_secondary btn_large"
                  onClick={submitHandler}
                >
                  Sign In
                </button>
              </div>
              <span>
                Don't have account?{" "}
                <Link to={"/signup"}>
                  {" "}
                  <a>Sign Up</a>{" "}
                </Link>
              </span>
              <div className="btn_container full_width">
                <Link to={"/vendor-login"}>
                  <button
                    className="btn btn_gradiant btn_large"
                    style={{ fontSize: "13px" }}
                  >
                    Sign in as a vendor
                  </button>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignIn;
