import React from "react";
import { toast } from "react-toastify";

const SelectCardType = ({ step, setstep, imageData, setimageData }) => {
  const clickHandler = () => {
    if (!imageData.cardType) {
      return toast.warn("Please select one");
    } else {
      setstep(step + 1);
    }
  };
  return (
    <div className="select-card">
      <h3 style={{ textAlign: "center", color: "#fff" }}>Select ID type</h3>
      <div
        className={`card ${imageData.cardType == "nid" && "selected"}`}
        onClick={() => setimageData({ ...imageData, cardType: "nid" })}
      >
        National ID Card
      </div>
      <div
        className={`card ${imageData.cardType == "passport" && "selected"}`}
        onClick={() => setimageData({ ...imageData, cardType: "passport" })}
      >
        Passport
      </div>
      <div
        className={`card ${
          imageData.cardType == "driving_licence" && "selected"
        }`}
        onClick={() =>
          setimageData({ ...imageData, cardType: "driving_licence" })
        }
      >
        Driving Licence
      </div>
      <div className="btn_container">
        <button className="btn btn_secondary" onClick={clickHandler}>
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectCardType;
