import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import { updateProfile } from "../../redux/actions/userAction";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const navigate=useNavigate()

  const [userData, setuserData] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
       return navigate("/sign-in")
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setuserData(user);
  }, [user]);

  const update = () => {
    dispatch(updateProfile({ ...userData, depositId: userData._id }));
  };

  return (
    <Layout>
      <div className="profile">
        <div className="formContainer3">
          <div className="form3">
            <div className="input">
              <label htmlFor=""></label>
              <input type="text" />
            </div>
            <div className="input">
              <label htmlFor=""></label>
              <input type="text" />
            </div>
            <div className="input">
              <label htmlFor=""></label>
              <input type="text" />
            </div>
            <div className="input">
              <label htmlFor=""></label>
              <input type="text" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
