import React from "react";
import { useRef } from "react";

const FrontCardVerify = ({
  step,
  setstep,
  imageData,
  setimageData,
  children,
  videoRef,
}) => {
  const photoRef = useRef();
  const takePhoto = () => {
    let photo = photoRef.current;
    let video = videoRef.current;
    const width = 400;
    const height = width / (16 / 9);

    photo.width = width;

    photo.height = height;

    let ctx = photo.getContext("2d");

    ctx.drawImage(video, 0, 0, width, height);

    const data = photo.toDataURL("image/jpeg");
    setimageData({ ...imageData, idFront: data });
  };
  return (
    <div className="card-verify">
      <h2 className=" ">Front Part of your ID</h2>

      <div className="photo-taker">
        {!imageData.idFront ? (
          <button className="btn btn_primary " onClick={takePhoto}>
            Take Photo
          </button>
        ) : (
          <button
            className="btn btn_primary "
            onClick={() => setimageData({ ...imageData, idFront: null })}
          >
            Take Again
          </button>
        )}

        <canvas
          style={{ display: "none" }}
          className="m-3"
          ref={photoRef}
        ></canvas>

        {imageData.idFront && (
          <img className="display_image m-3" src={imageData.idFront} alt="" />
        )}
      </div>
    </div>
  );
};

export default FrontCardVerify;
