import { toast } from "react-toastify";
import { dealerConstants } from "../constants/serviceConstants";
import axios, { backend_api } from "../utils/backend_api";

export const getCryptoSell = () => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_CRYPTO_SELL_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/dealers_sell`);

    dispatch({
      type: dealerConstants.GET_CRYPTO_SELL_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    dispatch({
      type: dealerConstants.GET_CRYPTO_SELL_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getCryptoBuy = (id) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_CRYPTO_BUY_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/dealers_buy`);

    dispatch({
      type: dealerConstants.GET_CRYPTO_BUY_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: dealerConstants.GET_CRYPTO_BUY_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getCashLoans = () => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_CASH_LOANS_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/dealers_loans`);

    dispatch({
      type: dealerConstants.GET_CASH_LOANS_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: dealerConstants.GET_CASH_LOANS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getAcceptedBuy = (id) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_ACCEPTED_BUY_REQUEST });

    const { data } = await axios.get(
      `${backend_api}/api/v1/dealer/cash_to_crypto/accepted`
    );

    dispatch({
      type: dealerConstants.GET_ACCEPTED_BUY_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    dispatch({
      type: dealerConstants.GET_ACCEPTED_BUY_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const takenLoanOrder = () => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_TAKEN_LOAN_ORDER_REQUEST });

    const { data } = await axios.get(
      `${backend_api}/api/v1/dealer/taken_loan_orders`
    );

    dispatch({
      type: dealerConstants.GET_TAKEN_LOAN_ORDER_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    dispatch({
      type: dealerConstants.GET_TAKEN_LOAN_ORDER_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const loanOrderSuccess = (id, info) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.MARK_LOAN_ORDER_SUCCESS_REQUEST });

    const { data } = await axios.put(
      `${backend_api}/api/v1/dealer/order_success/${id}`,
      info
    );

    dispatch({
      type: dealerConstants.MARK_LOAN_ORDER_SUCCESS_SUCCESS,
      payload: data.items,
    });

    toast.success("Successfully Submitted");
  } catch (error) {
    toast.error(error?.response?.data.message);
    dispatch({
      type: dealerConstants.MARK_LOAN_ORDER_SUCCESS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const getAcceptedSell = (id) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_ACCEPTED_SELL_REQUEST });

    const { data } = await axios.get(
      `${backend_api}/api/v1/dealer/crypto_to_cash/accepted`
    );

    dispatch({
      type: dealerConstants.GET_ACCEPTED_SELL_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    dispatch({
      type: dealerConstants.GET_ACCEPTED_SELL_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

// export const updateCryptoToCash = (id, loanData) => async (dispatch) => {
//   try {
//     dispatch({ type: cryptoToCashConstants.UPDATE_CRYPTO_TO_CASH_REQUEST });

//     const { data } = await axios.put(
//       `${backend_api}/api/v1/admin/crypto_to_cash/${id}`,
//       loanData
//     );

//     dispatch({
//       type: cryptoToCashConstants.UPDATE_CRYPTO_TO_CASH_SUCCESS,
//       payload: data.item,
//     });

//     console.log(data);
//     toast.success("Successfully Updated");
//   } catch (error) {
//     dispatch({
//       type: cryptoToCashConstants.UPDATE_CRYPTO_TO_CASH_FAILURE,
//       payload: error?.response?.data.message,
//     });
//   }
// };

export const acceptBuy = (id, _data) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.ACCEPT_BUY_REQUEST });

    const { data } = await axios.put(
      `${backend_api}/api/v1/dealer/cash_to_crypto/${id}`,
      _data
    );

    dispatch({
      type: dealerConstants.ACCEPT_BUY_REQUEST,
    });

    toast.success("Successfully Accepted");
  } catch (error) {
    toast.error(error?.response?.data?.message);

    dispatch({
      type: dealerConstants.ACCEPT_BUY_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const acceptSell = (id, _data) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.ACCEPT_SELL_REQUEST });

    const { data } = await axios.put(
      `${backend_api}/api/v1/dealer/crypto_to_cash/${id}`,
      _data
    );

    dispatch({
      type: dealerConstants.ACCEPT_SELL_SUCCESS,
    });

    toast.success("Successfully Accepted");
  } catch (error) {
    toast.error(error?.response?.data?.message);

    dispatch({
      type: dealerConstants.ACCEPT_SELL_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const takeLoanOrder = (id, _data) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.TAKE_LOAN_ORDER_REQUEST });

    const { data } = await axios.put(
      `${backend_api}/api/v1/dealer/take_loan_order/${id}`,
      _data
    );

    dispatch({
      type: dealerConstants.TAKE_LOAN_ORDER_SUCCESS,
    });

    toast.success("Successfully TAKEN");
  } catch (error) {
    toast.error(error?.response?.data?.message);

    dispatch({
      type: dealerConstants.TAKE_LOAN_ORDER_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

// Dealer Payment
export const getPayments = () => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_PAYMENTS_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/dealer/payments`);

    dispatch({
      type: dealerConstants.GET_PAYMENTS_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: dealerConstants.GET_PAYMENTS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const takePaymentOrder = (id, _data) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.TAKE_PAYMENT_ORDER_REQUEST });

    const { data } = await axios.put(
      `${backend_api}/api/v1/dealer/take_payment_order/${id}`,
      _data
    );

    dispatch({
      type: dealerConstants.TAKE_PAYMENT_ORDER_SUCCESS,
    });

    toast.success("Successfully TAKEN");
  } catch (error) {
    toast.error(error?.response?.data?.message);

    dispatch({
      type: dealerConstants.TAKE_PAYMENT_ORDER_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

export const takenPaymentOrder = () => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.GET_TAKEN_PAYMENT_ORDER_REQUEST });

    const { data } = await axios.get(
      `${backend_api}/api/v1/dealer/taken_payment_order`
    );

    dispatch({
      type: dealerConstants.GET_TAKEN_PAYMENT_ORDER_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    dispatch({
      type: dealerConstants.GET_TAKEN_PAYMENT_ORDER_SUCCESS,
      payload: error?.response?.data.message,
    });
  }
};

export const paymentOrderSuccess = (id, info) => async (dispatch) => {
  try {
    dispatch({ type: dealerConstants.MARK_PAYMENT_ORDER_SUCCESS_REQUEST });

    const { data } = await axios.put(
      `${backend_api}/api/v1/dealer/mark_payment_order_success/${id}`,
      info
    );

    dispatch({
      type: dealerConstants.MARK_PAYMENT_ORDER_SUCCESS_SUCCESS,
    });
    toast.success("Successfully Submitted");
  } catch (error) {
    dispatch({
      type: dealerConstants.MARK_PAYMENT_ORDER_SUCCESS_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};
