import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../redux/actions/userAction";
import DashboardSidebar from "./DashbaordSidebar";
import DashboardHeading from "./DashboardHeading";
import styles from "./styles.module.scss";

const DashboardLayout = ({ children, vendor }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserData(user._id));
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/signin");
    } else {
      // if (user.role == "dealer") {
      //   return navigate("/vendor/*");
      // } else {
      //   return navigate("/account/*");
      // }
    }
  }, [user]);

  return (
    <div className={styles.dashboard}>
      <div>
        <DashboardSidebar vendor={vendor} />
      </div>
      <div className={styles.content}>
        <DashboardHeading />
        <div className={styles.dashboardMain}>{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
