import React from "react";
import { Zoom } from "react-reveal";
import { Link } from "react-router-dom";
import "./styles.scss";

const PassiveIncome = () => {
  return (
    <div className="passiveIncome">
      <div className="container">
        <img src="images/shape2.png" alt="" className="shape" />
        <Zoom>
          <img className="earth2" src="images/earth2.png" alt="" />
        </Zoom>
        <Zoom>
          <img className="earth" src="images/earth.png" alt="" />
        </Zoom>
        <h3 className="gradiant_text4">Earn a Business Income as a vendor.</h3>
        <Zoom cascade>
          <div className="items_container">
            <Link to={"/vendor-login"}>
              <div className="item">
                <img src="images/security.png" alt="" />
                <div>
                  <h4>1. How to join? </h4>
                  <p>
                    Buy 99Krypto tokens in pancake swap or any other exchange
                    and open a deposit or stake in the pool to obtain
                    transaction ID.
                  </p>
                </div>
              </div>
            </Link>
            <Link to={"/vendor-login"}>
              <div className="item">
                <img src="images/security.png" alt="" />
                <div>
                  <h4>2.Create Vendor Account </h4>
                  <p>
                    Create a vendor account by using transaction ID of the
                    deposit/stake and start receiving orders in your account.
                  </p>
                </div>
              </div>
            </Link>
            <div className="item">
              <img src="images/security.png" alt="" />
              <div>
                <h4>3.Complete transactions </h4>
                <p>
                  Accept orders which you can deliver and contact the customer
                  to complete the transaction. Obtain OTP enter it in your order
                  page for successful transaction.
                </p>
              </div>
            </div>
            <div className="item">
              <img src="images/security.png" alt="" />
              <div>
                <h4>4.Transaction Settlement</h4>
                <p>
                  99Krypto verifies the transaction and releases you the
                  settlement with applicable transactional fee deducted.
                </p>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default PassiveIncome;
