import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions/userAction";
import styles from "./styles.module.scss";

const DashboardHeading = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <div className={styles.heading}>
      <div className={styles.headingLeft}>
        <Link  to='/account' >
        <img src="../images/logo.png" alt="" />
        </Link>
 
        <h3>
          {user.role == "user" ? (
            <Link to={"/account"} >
              <a href="">Hi, {user?.firstName} </a>
            </Link>
          ) : (
            <Link to="/vendor/profile" >
              <a href="">Hi, {user?.firstName} </a>
            </Link>
          )}
        </h3>
      </div>
      <div className={styles.headingRight}>
        <ul>
          <li>
            <Link to="/">Home</Link>{" "}
          </li>
        </ul>
        <button
          className="btn btn_secondary"
          onClick={() => dispatch(logout())}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default DashboardHeading;
