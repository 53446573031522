import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import ChangePassword from "./pages/Auth/ChangePassword";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import BuySell from "./pages/BuySell";
import CashLoans from "./pages/CashLoans";
import CryptoLoans from "./pages/CryptoLoans";

// users account components
import UserCashLoans from "./pages/Dashboard/UserCashLoans";
import UserCryptoLoans from "./pages/Dashboard/UserCryptoLoans";
import UserDeposits from "./pages/Dashboard/UserDeposits";
import UserPayments from "./pages/Dashboard/UserPayments";
import UserBuy from "./pages/Dashboard/UserBuy";
import UserEscrows from "./pages/Dashboard/UserEscrows";
import UserSwap from "./pages/Dashboard/UserSwap";
import UserProfile from "./pages/Dashboard/UserProfile";

import Deposit from "./pages/Deposit";
import Escrow from "./pages/Escrow";
import FindTransaction from "./pages/FindTransaction";
import Home from "./pages/Home";
import KYC from "./pages/KYC";
import Payment from "./pages/Payment";
import Press from "./pages/Press";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Stake from "./pages/Stake";
import Success from "./pages/Success";
import Swap from "./pages/Swap";
import Vendor from "./pages/Vendor";
import VendorBuy from "./pages/Vendor/VendorBuy";
import VendorCashLoans from "./pages/Vendor/VendorCashLoans";
import VendorProfile from "./pages/Vendor/VendorProfile";
import VendorSell from "./pages/Vendor/VendorSell";
import VendorLogin from "./pages/VendorLogin/VendorLogin";
import WhitePaper from "./pages/WhitePaper";
import {
  getCryptoCoins,
  getCurrency,
  getSetting,
  getTimePeriods,
  getWallets,
} from "./redux/actions/serviceAction";
import UserSell from "./pages/Dashboard/UserSell";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getCryptoCoins());
    dispatch(getTimePeriods());
    dispatch(getWallets());
    dispatch(getSetting());
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path={"/"} element={<Home />} />;
          <Route exact path={"/cash-loans"} element={<CashLoans />} />;
          <Route exact path={"/crypto-loans"} element={<CryptoLoans />} />;
          <Route exact path={"/deposits"} element={<Deposit />} />;
          <Route exact path={"/payment"} element={<Payment />} />;
          <Route exact path={"/escrow"} element={<Escrow />} />;
          <Route exact path={"/swap"} element={<Swap />} />;
          <Route exact path={"/stake"} element={<Stake />} />;
          <Route
            exact
            path={"/find-transaction"}
            element={<FindTransaction />}
          />
          <Route exact path={"/change-password"} element={<ChangePassword />} />
          <Route exact path={"/reset-password"} element={<ForgetPassword />} />
          <Route
            exact
            path={"/reset-password/:token"}
            element={<ResetPassword />}
          />
          {/* User Accout Routes */}
          <Route exact path={"/account"} element={<UserProfile />} />
          <Route
            exact
            path={"/account/cash-loans"}
            element={<UserCashLoans />}
          />
          <Route
            exact
            path={"/account/crypto-loans"}
            element={<UserCryptoLoans />}
          />
          <Route exact path={"/account/profile"} element={<UserProfile />} />
          <Route exact path={"/account/deposits"} element={<UserDeposits />} />
          <Route exact path={"/account/payment"} element={<UserPayments />} />;
          <Route exact path={"/account/buy"} element={<UserBuy />} />;
          <Route exact path={"/account/sell"} element={<UserSell />} />;
          <Route exact path={"/account/swap"} element={<UserSwap />} />;
          <Route exact path={"/account/escrow"} element={<UserEscrows />} />;
          {/* Vendor Account Routes */}
          <Route exact path={"/vendor"} element={<Vendor />} />
          <Route exact path={"/vendor/profile"} element={<VendorProfile />} />
          <Route
            exact
            path={"/vendor/cash-loans"}
            element={<VendorCashLoans />}
          />
          <Route exact path={"/vendor/buy"} element={<VendorBuy />} />
          <Route exact path={"/vendor/sell"} element={<VendorSell />} />
          ;
          <Route exact path={"/press"} element={<Press />} />;
          <Route exact path={"/signin"} element={<SignIn />} />;
          <Route exact path={"/signup"} element={<SignUp />} />;
          <Route exact path={"/kyc"} element={<KYC />} />
          <Route exact path={"/vendor-login"} element={<VendorLogin />} />;
          <Route exact path={"/profile"} element={<Profile />} />;
          {/* <Route exact path={"/white-paper"} element={<WhitePaper />} />; */}
          <Route exact path={"/buy-sell"} element={<BuySell />} />;
          <Route exact path={"/success"} element={<Success />} />;
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
