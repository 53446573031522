import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Hero from "../../components/CashLoans/Hero";
import Layout from "../../components/layout";
import Upload from "../../components/utils/Upload";
import Select from "../../components/utils/Select";
import {
  applyLoan,
  otpSend,
  otpVerify,
} from "../../redux/actions/serviceAction";
import { Slide } from "react-reveal";
import OtpBox from "../../components/utils/OtpBox";
import CopyToClipboard from "react-copy-to-clipboard";
import HowItWorks from "../../components/utils/HowItWorks.jsx";
import { cashLoans } from "../../components/utils/howItWorks";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/utils/LoginModal";

const CashLoans = () => {
  const dispatch = useDispatch();
  const {
    currencies,
    timePeriods,
    coins,
    wallets,
    success,
    otpSent,
    otpVerified,
    setting,
  } = useSelector((state) => state.service);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [isCopied, setisCopied] = useState(false);
  const [addContent, setaddContent] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [signinModal, setsigninModal] = useState(false);

  const [coinPrice, setcoinPrice] = useState(0);
  const [collateralCoinPrice, setcollateralCoinPrice] = useState("0");
  const [screenShot, setscreenShot] = useState(null);
  const [decodedImage, setdecodedImage] = useState("");

  const [cryptoWallets, setcryptoWallets] = useState([]);
  const [periods, setperiods] = useState([]);

  const [countries, setcountries] = useState([]);

  const [wallet, setwallet] = useState("");
  const [otp, setotp] = useState("");
  // const [otpSent, setotpSent] = useState(false);
  const [loanData, setloanData] = useState({
    amount: 0,
    collateralRequired: "",
    timePeriod: {
      interest: "",
    },
    totalTobePaid: "0",
    bankDetails: "",
    collateralCoin: null,
    currency: null,
    country: "",
    city: "",
  });

  useEffect(() => {
    if (!loanData.collateralCoin) return;
    const _periods = timePeriods?.filter(
      (P) => P.coin._id == loanData.collateralCoin._id && P.for == "loan"
    );

    setperiods(_periods);
    setloanData({ ...loanData, timePeriod: { interest: "" } });
  }, [loanData.collateralCoin]);

  const calTotal = () => {
    if (!loanData.amount) return;
    if (!loanData.timePeriod?.numberOfMonths) return;

    const P = parseInt(loanData.amount);
    const r = loanData?.timePeriod?.interest * 0.01;
    const t = parseInt(loanData.timePeriod?.numberOfMonths);
    const total = P * (1 + r * t);

    setloanData({
      ...loanData,
      totalTobePaid: total.toFixed(2),
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (!loanData.email) {
      return toast.warn("Email is required");
    }
    if (!loanData.country) {
      return toast.warn("Country is required");
    }
    if (!loanData.city) {
      return toast.warn("City is required");
    }
    if (!loanData.bankDetails) {
      return toast.warn("Bank Details is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot Is Required");
    }
    if (!isChecked) {
      return toast.warn("Please Checkmark Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Verify your email..");
    }

    const form = new FormData();
    form.set("email", loanData.email);
    form.set("timePeriod", loanData.timePeriod._id);
    form.set("currency", loanData.currency._id);
    form.set("amount", loanData.amount);
    form.set("collateralRequired", loanData.collateralRequired);

    form.set("totalTobePaid", loanData.totalTobePaid);

    form.set("collateralCoin", loanData.collateralCoin._id);
    form.set("bankDetails", loanData.bankDetails);
    form.set("country", loanData.country);
    form.set("city", loanData.city);
    form.set("screenShot", screenShot);

    dispatch(applyLoan(form));
  };

  const getCoinPrice = async (coin, currency) => {
    if (!currency) return;
    if (!coin) return;
    const res = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`
    );
    setcoinPrice(res.data);
    const _coin = res.data[loanData?.collateralCoin?.id];
    setcollateralCoinPrice(
      parseFloat(_coin[loanData?.currency?.currency.toLowerCase()])
    );
    const percent = setting.loanCharge * 0.01;
    setloanData({
      ...loanData,
      collateralRequired: loanData.amount / percent / collateralCoinPrice,
    });
  };

  useEffect(() => {
    if (!loanData.amount) return;
    if (collateralCoinPrice == 0) return;
    const percent = setting.loanCharge * 0.01;
    const _required = loanData.amount / percent / collateralCoinPrice;
    if (loanData.timePeriod.interest) {
      calTotal();
    }

    setloanData({
      ...loanData,
      collateralRequired: _required,
    });
  }, [collateralCoinPrice]);

  useEffect(() => {
    calTotal();
  }, [loanData.timePeriod, loanData.amount]);

  const fileChange = (e) => {
    const file = e.target.files[0];
    const mb = 1024 * 1020;
    if (file.size > mb) {
      return toast.warn("File is above 1 Mb,Compress and try again");
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    setcryptoWallets(wallets);
  }, [wallets]);
  useEffect(() => {
    if (loanData.currency) {
      const items = currencies.filter(
        (c) => c.currency == loanData.currency?.currency
      );
      setcountries(items);
    }
  }, [loanData.currency]);

  useEffect(() => {
    setwallet(
      cryptoWallets.find(
        (wal) => wal?.coin?._id == loanData.collateralCoin?._id
      )
    );
    getCoinPrice(
      loanData?.collateralCoin?.id,
      loanData?.currency?.currency.toLowerCase()
    );
  }, [loanData.collateralCoin, loanData.currency, loanData.amount]);

  const applyHandler = () => {
    if (!loanData.amount) {
      return toast.warn("Please add amount");
    }
    if (!loanData?.currency?.currency) {
      return toast.warn("Please select currency");
    }
    if (!loanData?.collateralCoin?.name) {
      return toast.warn("Please select Coin");
    }
    if (!loanData?.timePeriod?.interest) {
      return toast.warn("Please select Time Period");
    }
    if (!isAuthenticated) {
      setsigninModal(true);
    }
    setaddContent(true);
  };

  useEffect(() => {
    if (success) {
      return navigate("/success");
    }
  }, [success]);
  useEffect(() => {
    if (isAuthenticated) {
      return setsigninModal(false);
    }
  }, [isAuthenticated, user]);

  const sendOtp = (e) => {
    e.preventDefault();

    dispatch(otpSend({ email: loanData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: loanData.email, otp }));
  };

  return (
    <Layout>
      <Hero />
      <LoginModal modalOpen={signinModal} closeModal={() => null} />

      <Slide left>
        <div className="formContainer">
          <div className="form">
            <Select
              options={currencies}
              label="Loan Currency"
              property="country"
              property2="currency"
              value={loanData?.currency?.currency}
              onChange={(item) =>
                setloanData({
                  ...loanData,
                  currency: item,
                })
              }
            />

            <div className="input">
              <label htmlFor="">Loan Amount</label>
              <input
                type="text"
                value={loanData.amount}
                onChange={(e) =>
                  setloanData({
                    ...loanData,
                    amount: e.target.value,
                  })
                }
              />
            </div>

            <Select
              property={"name"}
              options={coins}
              label="Collateral Coin"
              value={loanData.collateralCoin?.name}
              img="123"
              onChange={(value) => {
                setloanData({ ...loanData, collateralCoin: value });
                setwallet(wallets.find((w) => value._id === w.coin));
              }}
            />

            <div className="input">
              <label htmlFor="">Collateral required</label>
              <input
                type="text"
                value={`${
                  loanData?.collateralRequired
                    ? loanData?.collateralRequired
                    : "0"
                }`}
                disabled={true}
              />
            </div>

            <Select
              label="Time Periods"
              options={periods}
              value={loanData?.timePeriod?.name}
              property="name"
              onChange={(item) =>
                setloanData({ ...loanData, timePeriod: item })
              }
            />

            <div className="input">
              <label htmlFor="">Interest percent per Month</label>
              <input
                type="text"
                placeholder="%"
                value={`${loanData?.timePeriod?.interest} %`}
                disabled={true}
              />
            </div>
            <div className="input">
              <label htmlFor="">Total to be paid back</label>
              <input
                type="text"
                value={`${loanData?.totalTobePaid} ${
                  loanData?.currency?.currency
                    ? loanData?.currency?.currency
                    : ""
                }`}
                disabled={true}
              />
            </div>
            <div className="input">
              <label htmlFor="">Current Coin Price</label>
              <input
                type="text"
                value={`${collateralCoinPrice} ${
                  loanData?.currency?.currency
                    ? loanData?.currency?.currency
                    : ""
                }`}
                disabled={true}
              />
            </div>
            <div className="btn_container">
              <a href="#form2">
                <button className="btn btn_secondary" onClick={applyHandler}>
                  Apply
                </button>
              </a>
            </div>
          </div>
          <div className="howWorks">
            <HowItWorks items={cashLoans} />
          </div>
        </div>
      </Slide>

      {addContent && (
        <div className="formContainer2" id="form2">
          <div className="form2">
            <div className="input flex2 full_width " style={{ width: "100%" }}>
              <label htmlFor="">Send Collateral Coin to</label>
              <input
                type="text"
                placeholder="wallet address....."
                value={wallet?.address}
              />

              <CopyToClipboard
                text={wallet?.address}
                onCopy={() => setisCopied(true)}
              >
                <button className="copyButton">
                  {isCopied ? "Copied" : "Copy Address"}{" "}
                </button>
              </CopyToClipboard>
            </div>
            <Select
              label={"Country"}
              value={loanData?.country}
              options={countries}
              property="country"
              onChange={(item) =>
                setloanData({
                  ...loanData,
                  country: item.country,
                })
              }
            />
            <div className="input">
              <label htmlFor="">City</label>
              <input
                type="text"
                value={loanData?.city}
                placeholder="City Name"
                onChange={(e) =>
                  setloanData({ ...loanData, city: e.target.value })
                }
              />
            </div>
            <div className="input">
              <label htmlFor="">Email Address(Please Enter Carefully)</label>
              <input
                type="text"
                placeholder="Enter your email"
                value={loanData.email}
                disabled={otpSent}
                onChange={(e) =>
                  setloanData({
                    ...loanData,
                    email: e.target.value,
                  })
                }
              />
            </div>

            <Upload onChange={fileChange} src={screenShot} />

            <div className="input full_width flex2">
              <label htmlFor="">Bank/WU address to Receive Loan Amount</label>
              <input
                type="text"
                value={loanData.bankDetails}
                onChange={(e) =>
                  setloanData({
                    ...loanData,
                    bankDetails: e.target.value,
                  })
                }
              />
            </div>

            {otpSent && !otpVerified && (
              <OtpBox
                value={otp}
                onChange={(value) => setotp(value)}
                onClick={verifyOtp}
              />
            )}
            <div className="check ">
              <input
                type="checkbox"
                name=""
                id="check"
                onClick={() => setisChecked(true)}
              />
              <label htmlFor="check">terms & Cnditions</label>
            </div>
            <div className="btn_container  full_width">
              {otpSent ? (
                <button
                  className="btn btn_gradiant btn_large"
                  onClick={formSubmit}
                >
                  Apply
                </button>
              ) : (
                <button
                  className="btn btn_gradiant btn_large"
                  onClick={sendOtp}
                >
                  Send OTP
                </button>
              )}
            </div>

            <p>
              *For Quick loan repayments or part payments Contact
              Support@99krypto.com with your transaction ID and email address
            </p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CashLoans;
