import React from "react";
import { useSelector } from "react-redux";

const SwapTrack = () => {
  const { track: swapData } = useSelector((state) => state.service);

  return (
    <div className="track">
      <div className="info">
        <div className="key">
          <label> Status</label>
        </div>
        <div className="value">{swapData.status}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Swap Coin</label>
        </div>
        <div className="value">{swapData.crypto?.name} </div>
      </div>
      <div className="info">
        <div className="key">
          <label> Swap Quantity</label>
        </div>
        <div className="value">{swapData.quantity}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Recieving Crypto</label>
        </div>
        <div className="value">{swapData.receivingCrypto?.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Receiving Quantity</label>
        </div>
        <div className="value">{swapData.receivingQuantity}</div>
      </div>
    </div>
  );
};

export default SwapTrack;
