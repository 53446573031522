import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../components/Vendor/DashboardLayout'
import { acceptBuy, getAcceptedBuy, getCryptoBuy } from '../../redux/actions/dealerAction';

const VendorBuy = () => {
    const dispatch = useDispatch();
    const { buy,acceptedBuy } = useSelector((state) => state.dealer);
    const { user } = useSelector((state) => state.user);
  
    useEffect(() => {
      dispatch(getAcceptedBuy());
    }, []);


  
    const [securityCode, setsecurityCode] = useState("");
  
    useEffect(() => {
      dispatch(getCryptoBuy());
    }, []);
  
    const clickHandler = (id) => {
      dispatch(acceptBuy(id, { securityCode }));
  
      setsecurityCode("");
    };
  
    const isBlocked = (item) => {
      const length = item?.tried.filter((i) => i == user._id).length;
  
      return length >= 3 ? true : false;
    };


  return (
    <DashboardLayout vendor={true}>
         <div className="items">
            <h3>Accepted By you</h3>
        <div className="buyCard owned">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>
        </div>
        {acceptedBuy?.map((item, index) => (
          <div key={index} className="buyCard owned">
            <div>
              <span className="key"> Transaction ID :</span>{" "}
              <span> {item.transactionId} </span>
            </div>
            <div>
              <span className="key">Status :</span>{" "}
              <span className={item.status}>{item.status}</span>{" "}
            </div>

            <div>
              <span className="key">Amount :</span> <span>{item.amount} </span>{" "}
            </div>
            <div>
              <span className="key">Currency :</span>{" "}
              <span>{item.currency?.currency} </span>
            </div>
            <div>
              <span className="key">Quantity :</span>{" "}
              <span> {item.quantity.toFixed(6)}</span>{" "}
            </div>

            <div>
              <span className="key">Coin :</span>{" "}
              <span> {item.coin?.name}</span>{" "}
            </div>
            <div>
              <span className="key">Country:</span> <span>{item.country}</span>{" "}
            </div>
            <div>
              <span className="key">City:</span> <span>{item.city}</span>{" "}
            </div>
          </div>
        ))}
      </div>
      <h3>New Arrivals</h3>

      <div className="items">
        <div className="buyCard">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>
          <div>Accept</div>
        </div>
        {buy?.map((item, index) => (
          <div key={index} className="buyCard">
            <div>
              <strong className="key">Transation ID :</strong>{" "}
              <span>{item.transactionId}</span>{" "}
            </div>
            <div>
              <strong className="key">Status:</strong>{" "}
              <span> {item.status}</span>{" "}
            </div>

            <div>
              <strong className="key">Amount:</strong>{" "}
              <span>{item.amount}</span>{" "}
            </div>
            <div>
              <strong className="key">Currency:</strong>{" "}
              <span>{item.currency?.currency} </span>{" "}
            </div>
            <div>
              <strong className="key">Quantity:</strong>{" "}
              <span>{item.quantity}</span>{" "}
            </div>

            <div>
              <strong className="key">Coin:</strong>{" "}
              <span>{item.coin?.name}</span>{" "}
            </div>
            <div>
              <strong className="key">Country:</strong>
              <span> {item.country}</span>{" "}
            </div>
            <div>
              <strong className="key">City:</strong> <span>{item.city}</span>{" "}
            </div>
            {isBlocked(item) ? (
              <div>
                <button className="btn btn-danger">Blocked</button>{" "}
              </div>
            ) : (
              <div>
                <input
                  type="number"
                  value={securityCode}
                  placeholder="Security Code"
                  onChange={(e) => setsecurityCode(e.target.value)}
                />
                <button
                  className="btn btn_primary"
                  onClick={() => clickHandler(item._id)}
                >
                  Accept
                </button>{" "}
              </div>
            )}
          </div>
        ))}
      </div>

    </DashboardLayout>
  )
}

export default VendorBuy