import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Zoom } from "react-reveal";
import { BsCheck2All } from "react-icons/bs";

const Hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="left">
          <h2 className="gradiant_text">Cash Loans</h2>

          <h3 className="gradiant_text">
            <span className="tick">
              <BsCheck2All size={23} />
            </span>
            Cash to your Bank Account in 24 to 48 hours
          </h3>
          <h3 className="gradiant_text">
            <span className="tick">
              <BsCheck2All size={23} />
            </span>
            Up to 80% Cash Loan on your Asset Value
          </h3>
          <h3 className="gradiant_text">
            <span className="tick">
              <BsCheck2All size={23} />
            </span>
            No monthly Interest Payments
          </h3>
        </div>
        <div className="right">
          <Zoom>
            <LazyLoadImage src="images/cash_loans.png" />
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default Hero;
