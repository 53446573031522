import React from "react";
// import { toast } from "react-toastify";

const Upload = ({ onChange, src, onRemove, name }) => {
  return (
    <div className="upload_container input">
      <label htmlFor="upload">
        <label>Screen shot of Transfer</label>
        <div className="upload">
          {src ? (
            <img src={src} alt="" />
          ) : (
            <span>Drag & Drop your Files Here</span>
          )}
        </div>
      </label>
      <input onChange={onChange} name={name} type="file" id="upload" />
    </div>
  );
};

export default Upload;
