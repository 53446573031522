import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/layout";
import Password from "../../components/utils/Password";
import { register } from "../../redux/actions/userAction";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  const [passwordValid, setpasswordValid] = useState(true);

  const [userData, setuserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    passwored: "",
    confirmPassword: "",
    phoneNo: "",
  });

  const submitHandler = () => {
    if (
      !userData.firstName ||
      !userData.lastName ||
      !userData.email ||
      !userData.password ||
      !userData.confirmPassword ||
      !userData.phoneNo
    ) {
      return toast.warn("All fields are required");
    }
    if (!passwordValid) {
      return toast.warn("Password is not valid.Please,use a strong password");
    }

    dispatch(register(userData));
  };

  useEffect(() => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
        userData.password
      )
    ) {
      setpasswordValid(true);
    } else {
      setpasswordValid(false);
    }
  }, [userData.password]);

  useEffect(() => {
    if (isAuthenticated) {
      return navigate("/account");
    }
  }, [isAuthenticated]);

  return (
    <Layout>
      <div className="signin">
        <div className="container">
          <img src="images/signin.png" alt="" />
          <div className="formContainer3">
            <h2 className="secondary">Sign Up</h2>
            <div className="form3" style={{ maxWidth: "600px" }}>
              <div className="input ">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  placeholder="first name"
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      firstName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input ">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="last name"
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      lastName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input ">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Your Email address"
                  value={userData.email}
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      email: e.target.value,
                    })
                  }
                />
              </div>
              <div className="input ">
                <label htmlFor="">Phone</label>
                <input
                  type="phone"
                  placeholder="Your phone number"
                  value={userData.phoneNo}
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      phoneNo: e.target.value,
                    })
                  }
                />
              </div>
              <Password
                type="text"
                placeholder="password..."
                value={userData.password}
                autoComplete={false}
                passwordValid={passwordValid}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    password: e.target.value,
                  })
                }
              />

              <Password
                type="text"
                placeholder="retype password..."
                value={userData.confirmPassword}
                label="Confirm Password"
                autoComplete={false}
                onChange={(e) =>
                  setuserData({
                    ...userData,
                    confirmPassword: e.target.value,
                  })
                }
              />
                  

              <div className="btn_container full_width">
                <button
                  className="btn btn_secondary btn_large"
                  onClick={submitHandler}
                >
                  Sign Up
                </button>
              </div>
              <span>
                Already have an account?{" "}
                <Link to={"/signin"}>
                  {" "}
                  <a>Sign In</a>{" "}
                </Link>
              </span>
              <div className="btn_container full_width">
                <Link to={"/vendor-login"}>
                  <button
                    className="btn btn_gradiant btn_large"
                    style={{ fontSize: "13px" }}
                  >
                    Register as a vendor
                  </button>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignUp;
