import { useState } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import Modal from "../utils/Modal";
import { useRef } from "react";

const ContactModal = ({ modalOpen, closeModal }) => {
  const form = useRef();
  const [data, setdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    if (!data.first_name) {
      return toast.warn("First name is required");
    }
    if (!data.last_name) {
      return toast.warn("Last name is required");
    }
    if (!data.email) {
      return toast.warn("Email is required");
    }
    if (!data.phone) {
      return toast.warn("Phone is required");
    }
    if (!data.message) {
      return toast.warn("Please write your message");
    }

    emailjs
      .sendForm(
        "godaddy_webmail",
        "template_85v6usq",
        form.current,
        "wX4krLu_KdqDVKprM"
      )
      .then(
        (result) => {
          setdata({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            message: "",
          });
          toast.success("Thank you ,your message has been sent to Support)");
          form.current = {};
        },
        (error) => {
          toast.error("Something went wrong.Please try again");
        }
      );
  };

  return (
    <Modal modalOpen={modalOpen} closeModal={closeModal}>
      <div
        className="contact_us formContainer3"
        style={{ width: "80vw", maxWidth: "900px", padding: "0" }}
      >
        <h1 className="gradiant_text3">Contact Us</h1>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="form3"
          style={{ width: "80vw", maxWidth: "800px" }}
        >
          <div className="input">
            <label htmlFor="">First Name</label>
            <input
              type="text"
              value={data.first_name}
              placeholder="Enter First Name"
              onChange={(e) => setdata({ ...data, first_name: e.target.value })}
            />
          </div>
          <div className="input">
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              value={data.last_name}
              placeholder="Enter Last Name"
              onChange={(e) => setdata({ ...data, last_name: e.target.value })}
            />
          </div>
          <div className="input">
            <label htmlFor="">Email</label>
            <input
              type="email"
              required
              value={data.email}
              id=""
              placeholder="Enter Email"
              onChange={(e) => setdata({ ...data, email: e.target.value })}
            />
          </div>
          <div className="input">
            <label htmlFor="">Phone Number</label>
            <input
              type="text"
              required
              value={data.phone}
              placeholder="Enter Phone Number"
              onChange={(e) => setdata({ ...data, phone: e.target.value })}
            />
          </div>
          <div className="input full_width">
            <label htmlFor="">Message</label>
            <textarea
              className="textarea "
              placeholder="Type your message.."
              style={{ resize: "none", height: "30vw", maxHeight: "250px" }}
              value={data.message}
              onChange={(e) => setdata({ ...data, message: e.target.value })}
            ></textarea>
          </div>
          <div className="btn_container">
            <button type="submit" className="btn btn_gradiant btn_medium">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ContactModal;
