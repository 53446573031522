import React from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../components/Vendor/DashboardLayout";

const UserBuy = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <DashboardLayout>
      <h2>Applications for crypto buy</h2>
      <div className="items">
        <div className="buyCard">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>
          <div>Accept</div>
        </div>
        {user.buy?.map((item, index) => (
          <div key={index} className="buyCard">
            <div>
              <strong className="key">Transation ID :</strong>{" "}
              <span>{item.transactionId}</span>{" "}
            </div>
            <div>
              <strong className="key">Status:</strong>{" "}
              <span> {item.status}</span>{" "}
            </div>

            <div>
              <strong className="key">Amount:</strong>{" "}
              <span>{item.amount}</span>{" "}
            </div>
            <div>
              <strong className="key">Currency:</strong>{" "}
              <span>{item.currency?.currency} </span>{" "}
            </div>
            <div>
              <strong className="key">Quantity:</strong>{" "}
              <span>{item.quantity}</span>{" "}
            </div>

            <div>
              <strong className="key">Coin:</strong>{" "}
              <span>{item.coin?.name}</span>{" "}
            </div>
            <div>
              <strong className="key">Country:</strong>
              <span> {item.country}</span>{" "}
            </div>
            <div>
              <strong className="key">City:</strong> <span>{item.city}</span>{" "}
            </div>
          </div>
        ))}
      </div>
    </DashboardLayout>
  );
};

export default UserBuy;
