import React from "react";
import Layout from "../layout";
import moment from "moment";
import { useSelector } from "react-redux";

const CashLoanTrack = () => {
  const { track: loanData } = useSelector((state) => state.service);

  return (
 
      <div className="track">
        <div className="info">
          <div className="key">
            <label> Status</label>
          </div>
          <div className="value">{loanData?.status}</div>
        </div>
        <div className="info">
          <div className="key">
            <label> Loan Currency</label>
          </div>
          <div className="value">{loanData?.currency?.currency}</div>
        </div>
        <div className="info">
          <div className="key">
            <label> Loan Amount</label>
          </div>
          <div className="value">{loanData?.amount}</div>
        </div>
        <div className="info">
          <div className="key">
            <label> Collateral Coin</label>
          </div>
          <div className="value">{loanData?.collateralCoin?.name}</div>
        </div>
        <div className="info">
          <div className="key">
            <label> Collateral Quantity</label>
          </div>
          <div className="value">{loanData?.collateralRequired}</div>
        </div>
        <div className="info">
          <div className="key">
            <label> Total to be paid back</label>
          </div>
          <div className="value">{`${loanData?.totalTobePaid} ${
            loanData?.currency?.currency ? loanData?.currency?.currency : ""
          }`}</div>
        </div>
        <div className="info">
          <div className="key">
            <label> Outstanding Amount</label>
          </div>
          <div className="value">
            {loanData?.totalTobePaid - loanData?.partialRepayment}
          </div>
        </div>
        <div className="info">
          <div className="key">
            <label> Loan Start Date</label>
          </div>
          <div className="value">
            {moment(loanData.loanStartDate).format("MMMM Do YYYY, h:mm:ss a")}
          </div>
        </div>
        <div className="info">
          <div className="key">
            <label> Loan End Date</label>
          </div>
          <div className="value">
            {moment(loanData.loanEndDate).format("MMMM Do YYYY, h:mm:ss a")}
          </div>
        </div>
      </div>
  
  );
};

export default CashLoanTrack;
