import React from "react";
import Modal from "./Modal";
const AnnounceModal = ({ modalOpen, closeModal }) => {
  return (
    <Modal modalOpen={modalOpen} closeModal={closeModal}>
      <div  style={{padding:"3vw"}}>
        <h3 style={{textAlign:"center"}}>
          "This Website has not commenced Operations. <br /> All Transactions are for
          Demo & Testing purposes only"
        </h3>
        <div className="btnContainer" style={{textAlign:'right',justifyContent:"flex-end",alignItems:"flex-end"}}>
          <button onClick={closeModal} className="btn btn_primary"  style={{marginLeft:"auto"}}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AnnounceModal;
