import React from "react";
import "./styles.scss";
import { Slide, Zoom } from "react-reveal";
import Blob1 from "../Blobs/Blob1";
import { TypeAnimation } from "react-type-animation";
const Features = () => {
  return (
    <div className="features">
      

      <div className="container">
       

        <p  className="gradiant_text4" style={{fontSize:"18px",fontWeight:"600"}} >
        First peer to vendor platform, facilitates in person cash transactions in your city
        </p>
        <Zoom cascade left>
          <div className="items_container">
            <div className="item">
              <div>
                <img src="images/safety.png" alt="" />
              </div>
              <h4>SAFETY</h4>
              <p>
              Transactions through our vendor partners are fully safe. All our vendors are KYC verified and bonded with Crypto Deposits with us.
              </p>
            </div>
            <div className="item">
              <div>
                <img src="images/security.png" alt="" />
              </div>
              <h4>Security</h4>
              <p>
              Each transaction is assigned with a secure OTP. Vendors are mandated to secure OTP from customers after successful transactions.
              </p>
            </div>
            <div className="item">
              <div>
                <img src="images/speed.png" alt="" />
              </div>
              <h4>SPEED</h4>
              <p>
              All our transactions are fast, facilitating in person and online transactions.Most of our transactions complete within 0-24 hours.
              </p>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default Features;
