import React from "react";

const Blob3 = () => {
  return (
    <div>
      <svg
        viewBox="0 0 800 500"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
      >
        <g transform="translate(353, -119)">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: "#f97125" }} />
              <stop offset="100%" style={{ stopColor: "#f2b933" }} />
            </linearGradient>
          </defs>
          <path fill="url(#gradient)"  d="  M444.5,323Q451,396,376.5,402.5Q302,409,247.5,417Q193,425,162,380.5Q131,336,121,293Q111,250,129,212.5Q147,175,160.5,96Q174,17,238,53.5Q302,90,371.5,100.5Q441,111,439.5,180.5Q438,250,444.5,323Z;" >
         
          </path>
        </g>
      </svg>
    </div>
  );
};

export default Blob3;
