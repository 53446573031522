import React, { useState } from "react";

// SWIPER
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import "./styles.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Roll } from "react-reveal";
import Blob2 from "../Blobs/Blob2";
import Blob3 from "../Blobs/Blob3";
import { TypeAnimation } from "react-type-animation";
import Blob1 from "../Blobs/Blob1";
import Modal from "../utils/Modal";
import ContactModal from "../ContactModal/ContactModal";

const Hero = () => {
  const [contactModal, setcontactModal] = useState(false);
  const slider = [
    {
      content: "Highest Loans on Crypto Assets",
      img: "/images/slider/slider1.png",
      link: "/crypto-loans",
    },
    {
      content: "Earn Big on Staking & Deposits",
      img: "/images/slider/slider2.png",

      link: "/deposits",
    },
    {
      content: "You Pay us in Crypto ,We pay in Cash",
      img: "/images/slider/slider3.png",

      link: "/crypto-cash",
    },
    {
      content: "Pay Invoices, Buy Cars , Houses",
      img: "/images/slider/slider4.png",

      link: "/payment",
    },
    {
      content: "First Crypto Escrow Platform",
      img: "/images/slider/slider5.png",

      link: "/escrow",
    },
    {
      content: "Earn Business Income as a Vendor",
      img: "/images/slider/slider6.png",

      link: "/vendor",
    },
  ];

  return (
    <div className="hero_container">
      <ContactModal
        modalOpen={contactModal}
        closeModal={() => setcontactModal(false)}
      />

      <div className="bubbles">
        <img src="/images/bubble.png" alt="" />
        <img src="/images/crypto/ave.png" alt="" />
        <img src="/images/crypto/bitcoin.png" alt="" />
        <img src="/images/crypto/eth.png" alt="" />
        <img src="/images/crypto/wbtc.png" alt="" />
        <img src="/images/bubble.png" alt="" />
        <img src="/images/crypto/bitcoin.png" alt="" />
        <img src="/images/crypto/xrp.png" alt="" />
        <img src="/images/crypto/bnb.png" alt="" />
        <img src="/images/crypto/litecoin.png" alt="" />
        <img src="/images/bubble.png" alt="" />
        <img src="/images/crypto/polygon.png" alt="" />
      </div>
      <div style={{ position: "absolute", width: "50vw" }}>
        <Blob2 />
      </div>
      <div
        className="shape"
        style={{
          position: "absolute",
          bottom: "6vw",
          width: "500vw",
          height: "100vh",
        }}
      >
        <Blob3 />
      </div>
      <div className="contents">
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 8500,
            disableOnInteraction: false,
          }}
          className="mySwiper"
        >
          {slider.map((content, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="single_swiper ">
                  <div className="wrapper">
                    <div className="left">
                      <h1 className="gradiant_text4">{content.content}</h1>
                      <div className="buttons">
                        <Link to={content.link}>
                          <button className="mt-3 lets_start">
                            Let’s Start
                          </button>
                        </Link>

                        <Link to="/">
                          <button
                            onClick={() => setcontactModal(true)}
                            className="contact_us"
                          >
                            Contact Us
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="right">
                      <LazyLoadImage
                        style={{ minHeight: "200px" }}
                        src={content.img}
                        alt={content.content}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="container">
        <img src="images/icon.png" alt="" />

        <TypeAnimation
          sequence={[
            "Loved by millions",
            2000,
            " Trusted with billions.",
            2000,
          ]}
          speed={50}
          wrapper="h2"
          repeat={Infinity}
          cursor={true}
          className="gradiant_text"
        />

        <div className="shape2"></div>
      </div>
      <div
        style={{
          position: "absolute",
          width: "50vw",
          height: "50vw",
          bottom: "-300px",
          left: "0",
        }}
        className="shape1"
      >
        <Blob1 />
      </div>
    </div>
  );
};

export default Hero;
