import React, { useRef, useState } from "react";

const Select = ({
  backgroundColor,
  label,
  options,
  value,
  onChange,
  property,
  property2,
  img,
  className,
}) => {
  const [active, setactive] = useState(false);
  const options_container = useRef(null);

  const displayBlock = () => {
    options_container.current.style.display = "block";
  };

  const displayNone = () => {
    options_container.current.style.display = "none";
  };

  return (
    <div
      className={`input ${className} `}
      onMouseOver={displayBlock}
      onMouseLeave={displayNone}
    >
      <label htmlFor="">{label} </label>
      <div className="selectWrapper">
        <div
          style={{
            backgroundColor: backgroundColor,
            color: backgroundColor && "#fff",
          }}
          className="select"
          onClick={() => setactive(true)}
        >
          {value ? <span>{value}</span> : <span>Select</span>}
        </div>

        {active && (
          <ul ref={options_container}>
            {options?.map((item, i) => (
              <li
                onClick={() => {
                  onChange(item);
                  setactive(false);
                  // setActive(property2 ? item[property2] : item[property]);
                  // options_container.current.style.display = "none";
                }}
                key={i}
              >
                <div>{item[property]} </div>
                {property2 && <div> {item[property2]} </div>}

                {img && (
                  <div>
                    <img
                      style={{
                        width: "30px",
                        alignSelf: "flex-start",
                        height: "30px",
                        borderRadius: "50%",
                        transform: "rotate(360deg)",
                      }}
                      src={item?.logo?.url}
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <select
        name="
  "
        id=""
        onChange={ onChange}
      >
        {options?.map((opt, index) => (
          <option
            key={index}
            value={opt}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span> {opt[property]} </span>
            <span style={{ marginLeft: "100px" }}> {opt[property2]} </span>
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default Select;
