import React from "react";
import { useSelector } from "react-redux";

const PaymentTrack = () => {
  const { track: paymentData } = useSelector((state) => state.service);

  return (
    <div className="track">
      <div className="info">
        <div className="key">
          <label> Status</label>
        </div>
        <div className="value">{paymentData.status}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Sent Coin</label>
        </div>
        <div className="value">{paymentData.coin?.name}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Sent Quantity</label>
        </div>
        <div className="value">{paymentData.totalTobePaid}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Payable Currency</label>
        </div>
        <div className="value">{paymentData.currency?.currency}</div>
      </div>
      <div className="info">
        <div className="key">
          <label> Payable Amount</label>
        </div>
        <div className="value">{paymentData.amount}</div>
      </div>
    </div>
  );
};

export default PaymentTrack;
