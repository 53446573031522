import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Zoom } from "react-reveal";
import { BsCheck2All } from "react-icons/bs";
const Hero = () => {
  return (
    <div className="hero payment">
      <div className="container">
        <div className="left">
          <h2 className="gradiant_text3">Escrow Service</h2>

          <h3 className="gradiant_text4 ">
            <span className="tick">
              <BsCheck2All size={24} />
            </span>
            We govern your agreements for goods and services by being a third
            party.
          </h3>
          <h3 className="gradiant_text4">
          <span className="tick">
              <BsCheck2All size={24} />
            </span>
            We receive and disburse the crypto payments on meeting the terms of
            agreement set by transacting parties.
          </h3>
          <h3 className="gradiant_text4">
          <span className="tick">
              <BsCheck2All size={24} />
            </span>
            Immediate disbursal of crypto payments as soon as the parties agree
            to release the payment or pre-set agreement terms are met by payment
            receiving party.
          </h3>
          <h3 className="gradiant_text4">
          <span className="tick">
              <BsCheck2All size={24} />
            </span>
            Safeguards buyer and seller, no counter party risk
          </h3>
        </div>
        <div className="right">
          <Zoom>
            <LazyLoadImage src="images/escrow.png" alt="" />
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default Hero;
