import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../redux/actions/userAction";
import Modal from "./Modal";

const LoginModal = ({ modalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [userData, setuserData] = useState({
    email: "",
    password: "",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    if (!userData.email || !userData.password) {
      return toast.warn("All fields are required");
    }

    dispatch(login(userData.email, userData.password));
  };
  return (
    <Modal modalOpen={modalOpen} closeModal={closeModal}>
      <div
        className="formContainer2"
        style={{ width: "80vw", maxWidth: "800px" }}
      >
        <form
          action="
            "
          className="form2"
          onSubmit={submitHandler}
        >
          <h2
            className="gradiant_text3 full_width"
            style={{ textAlign: "center" }}
          >
            Sign In
          </h2>
          <div className="input full_width">
            <label htmlFor="">Email</label>
            <input
              type="text"
              placeholder="Your Email"
              autoComplete={false}
              onChange={(e) =>
                setuserData({
                  ...userData,
                  email: e.target.value,
                })
              }
            />
          </div>

          <div className="input full_width">
            <label htmlFor="">Password</label>
            <input
              type="text"
              placeholder="password..."
              onChange={(e) =>
                setuserData({
                  ...userData,
                  password: e.target.value,
                })
              }
            />
          </div>
          <span style={{ marginLeft: "auto" }}>
            <Link to={"/reset-password"}>
              <a>Forgot Password?</a>{" "}
            </Link>
          </span>
          <div className="btn_container full_width">
            <button
              className="btn btn_secondary btn_medium"
              onClick={submitHandler}
            >
              Sign In
            </button>
          </div>
          <span style={{ margin: "0 auto" }}>
            Don't have account?{" "}
            <Link to={"/signup"}>
              <a>Sign Up</a>{" "}
            </Link>
          </span>
        </form>
      </div>
    </Modal>
  );
};

export default LoginModal;
