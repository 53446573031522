import React from 'react'
import Layout from '../../components/layout'

const Press = () => {
  return (
 <Layout>
       <div  className='press' >
        <div className="container">
        <h1>Press : </h1>
                <div className="images">
                  <img src="images/news/news1.png" alt="" />
                  <img src="images/news/news2.png" alt="" />
                  <img src="images/news/news3.png" alt="" />
                  <img src="images/news/news4.png" alt="" />
                  <img src="images/news/news5.png" alt="" />
                  <img src="images/news/news6.png" alt="" />
                </div>

        </div>
               
    </div>
 </Layout>
  )
}

export default Press