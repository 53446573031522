

import React from 'react'

const Hero = () => {
    return (
        <div className='hero payment'  >
            <div className="container"  >
                <div className='left' >
                    <h2  className='gradiant_text4' >Track Application</h2>



                </div>
                <div className="right">
                <img src="images/find.png" alt="" />



                </div>

            </div>


        </div>
    )
}

export default Hero