import React from 'react'

const Spinner = () => {
  return (
    <div  className='spinnerContainer' >
        <div className="spinner">

        </div>
        <h3 className="gradiant_text">Please wait...</h3>

    </div>
  )
}

export default Spinner